import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fetch", "list"];

  fetchPosts = async () => {
    if (!this.hasFetchTarget) return;
    if (!this.listTarget.firstElementChild) {
      this.fetchTarget.style.display = "none";
      return;
    }

    const target = this.fetchTarget;
    const fetchAction = target.dataset.fetchAction;
    const dataset = target.dataset;

    if (!fetchAction) return;

    target.firstElementChild.disabled = true;

    const response = await fetch(
      `/api/v2/storefront/posts/${fetchAction}?` +
        new URLSearchParams({
          slug: dataset.slug,
          author_id: dataset.authorId,
          tag_ids: dataset.tagIds,
          blog_id: dataset.blogId,
          last_record_timestamp: dataset.lastRecordTimestamp,
        }),
      {
        method: "GET",
      }
    );
    const result = await response.json();
    const posts = result.data;
    const lastTimestamp = posts && posts.slice(-1)[0]?.attributes?.published_at;

    this.addPosts(posts, target);

    if (lastTimestamp) dataset.lastRecordTimestamp = lastTimestamp;
  };

  addPosts = (posts, target) => {
    if (!this.listTarget.firstElementChild) return;

    this.listTarget.style.maxHeight = `${this.listTarget.clientHeight}px`;

    posts.forEach((p) => {
      const post = this.listTarget.firstElementChild.cloneNode(true);
      const image = post.querySelector(".c-articleCard__image");
      const meta = post.querySelector(".c-articleCard__meta");
      const title = post.querySelector(".c-articleCard__title");

      const img = image.querySelector("img");
      const titleLink = title.querySelector("a");

      image.querySelector("a").href = p.attributes.link_url;
      img.src = "";
      img.src = p.attributes.mobile_image_url;
      img.alt = p.attributes.image_alt;

      meta.querySelector("a").href = p.attributes.author_url;
      meta.querySelector(".author").innerHTML = p.attributes.author_name;
      meta.querySelector(".time").innerHTML = p.attributes.published_at_date;

      titleLink.href = p.attributes.link_url;
      titleLink.firstElementChild.innerHTML = p.attributes.title;

      this.listTarget.appendChild(post);
    });

    this.listTarget.style.maxHeight = `${this.listTarget.scrollHeight}px`;

    this.removeButton(posts, target);
  };

  removeButton = (posts, target) => {
    setTimeout(() => {
      this.listTarget.style.removeProperty("max-height");
      target.firstElementChild.disabled = false;

      if (posts.length < 6) {
        target.style.animation = "fadeOut 1s";

        setTimeout(() => (target.style.display = "none"), "700");
      }
    }, "1000");
  };
}
