import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["searchData", "dropdown", "washout"];

  searchResults = async (query, url, stockLocationId) => {
    if (query === "" || query === null) return {};

    const response = await fetch(`${url}?keywords=${query}`, {
      headers: {
        "Spree-Stock-Location-Id": stockLocationId,
      },
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;

      throw new Error(message);
    }

    return await response.json();
  };

  findItems = (e) => {
    const query = e.target.value;

    clearTimeout(this.timeout);

    this.timeout = setTimeout(async () => {
      const result = await this.searchResults(
        query,
        this.searchDataTarget.dataset.url,
        this.searchDataTarget.dataset.stockLocationId
      ).catch((e) => console.log(e.message));

      if (query === "" || query === null) {
        this.dropdownDisplay("none");

        return;
      }

      this.buildSearchDropdown(result, query);

      this.dropdownDisplay("block");
    }, 400);
  };

  closeDropdown = () => {
    this.dropdownDisplay("none");
  };

  dropdownDisplay = (display) => {
    this.dropdownTarget.style.display = display;
    this.washoutTarget.style.display = display;
  };

  buildSearchDropdown = (result, query) => {
    if (
      result.products.total === 0 &&
      result.taxons.length === 0 &&
      result.brands.length === 0 &&
      result.blogs.length === 0
    ) {
      this.dropdownTarget.innerHTML = `
        <div class="dropdownResults">
          <span class="noResults">
            Nema rezultata za traženi pojam.
          </span>
        </div>
      `;
      return;
    }

    const dropdownHtml = `
      <div class="dropdownResults">
        ${this.products(result.products, query)}
        <div class="dropdownResults--right">
          ${this.taxons(result.taxons, query)}
          ${this.brands(result.brands, query)}
          ${this.blogs(result.blogs, query)}
        </div>
      </div>
    `;

    this.dropdownTarget.innerHTML = dropdownHtml;
  };

  // Build List Logic

  products = (products, query) => {
    return `
      <div id="products-search-container" class="dropdownResults--left">
        <div id="products-search-body">
          ${this.title("Proizvodi")}
          <div id="products-search-list" class="dropdownResults--productsList">
            ${this.productList(products.list, query)}
          </div>
        </div>
        <div id="button-container" class="dropdownResults--button">
          <button
            id="products-total"
            type="submit"
            class="e-btn e-btn--primary"
            style="width: 100%;"
          >
            <span class="e-btn__text">
              Pogledaj sve proizvode <span style="font-weight: 400;">(${
                products.total
              })</span>
            </span><span>&#x279E;</span>
          </button>
        </div>
      </div>
    `;
  };

  productList = (productList, query) => {
    const currency = this.dropdownTarget.dataset.currencySymbol;
    const decimalMark = this.dropdownTarget.dataset.currencySymbol;
    const thousandsSeparator = this.dropdownTarget.dataset.currencySymbol;
    const precision = this.dropdownTarget.dataset.currencySymbol;

    const list = productList
      .map((p) => {
        return `
        <div class="dropdownResults--singleProduct">
          <a class="imgLink" href="${p.url}">
            ${
              (p.img && `<img class="img" src="${p.img}" alt="${p.name}"/>`) ||
              ""
            }
          </a>
          <div>
            <div>
              ${this.link(p.name, p.url, query)}
            </div>
            <div class="priceContainer">
              ${
                (p.pre_sale_price &&
                  `<div class="preSalePrice">
                    ${p.pre_sale_price} ${currency}
                  </div>
                  <div class="border"></div>
                  `) ||
                ""
              }
              <div class="price">${p.price || 0} ${currency}</div>
            </div>
          </div>
        </div>
      `;
      })
      .join("");

    return list;
  };

  taxons = (taxons, query) => {
    return `
      <div class="dropdownResults--taxonsList">
        ${this.title("Kategorije")}
        ${taxons.map((t) => this.button(t.name, t.url, query)).join("")}
      </div>
    `;
  };

  brands = (brands, query) => {
    return `
      <div class="dropdownResults--brandsList">
        ${this.title("Brand")}
        ${brands.map((b) => this.button(b.name, b.url, query)).join("")}
      </div>
    `;
  };

  blogs = (blogs, query) => {
    return `
      <div class="dropdownResults--blogsList">
        ${this.title("Blog")}
        ${blogs.map((b) => this.link(b.name, b.url, query)).join("")}
      </div>
    `;
  };

  button = (name, url, query) => {
    return `
      <a
        class="e-btn e-btn--primaryOutline e-btn--drowpdownSearchItems"
        href=${url}
      >
        ${this.hightlightSubstring(name, query)}
      </a>
    `;
  };

  link = (name, url, query) => {
    return `
      <div>
        <a
          class="dropdownResults--link"
          href=${url}
        >
          ${this.hightlightSubstring(name, query)}
        </a>
      </div>
    `;
  };

  title = (text) => {
    return `
      <div class="dropdownResults--title">
        ${text}
      </div>
    `;
  };

  hightlightSubstring = (string, substring) => {
    if (!string.toLowerCase().includes(substring.toLowerCase())) return string;

    let startIndex = 0;
    let index;
    let loop = true;
    let highlightedString = "";

    while (loop === true) {
      index = string.toLowerCase().indexOf(substring.toLowerCase(), startIndex);

      if (index <= -1) {
        highlightedString += string.substring(startIndex, string.length);
        loop = false;
        break;
      }

      if (startIndex === 0) {
        highlightedString += string.substring(startIndex, index);
        startIndex = index + substring.length;
        highlightedString += `<b>${string.substring(index, startIndex)}</b>`;
      } else {
        highlightedString += string.substring(startIndex, index);
        startIndex = index + substring.length;
        highlightedString += `<b>${string.substring(index, startIndex)}</b>`;
      }
    }

    // Alternative way of highlighting. Works differently. Uncomment and use if necessary.

    // Array.from(string).forEach((s) => {
    //   if (substring.toLowerCase().includes(s.toLowerCase())) {
    //     return (highlightedString += `<b>${s}</b>`);
    //   }

    //   return (highlightedString += s);
    // });

    return `<span style="word-break: break-all;">${highlightedString}</span>`;
  };
}
