class CartSettings {
  init() {
    let containers = document.querySelectorAll('.js-cartSettings')
    if (containers == null) {
      return;
    }

    containers.forEach(container => {
        let btnToggle = container.querySelector('.js-cartSettings__trigger')
        let target = container.querySelector('.js-cartSettings__target')
        let targetH = container.querySelector('.js-cartSettings__targetHeight')
        let closeBtn = container.querySelector('.js-cartSettings__close')

        btnToggle.addEventListener('click', (e) => {
          this.toggle(container, target)
          this.updateHeight(target, targetH)
        });

        closeBtn.addEventListener('click', (e) => {
          this.close(container, target)
        });

    });
  }

  toggle(container, target) {
    container.classList.contains('state--active') ? this.close(container, target) : this.open(container)
  }

  open(container) {
    container.classList.add('state--active')
  }

  close(container, target) {
    container.classList.remove('state--active')
    target.style.height = '0px'
  }

  updateHeight(target, targetH) {
    let totalHeight = 0
    totalHeight = targetH.offsetHeight
    target.style.height = `${totalHeight}px`
  }
}

export let cartSettings = new CartSettings();
