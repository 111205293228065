import { Controller } from 'stimulus';
import { googleTagManager } from "../packs/scripts/helpers/GoogleTagManager";

export default class extends Controller {
  static targets = ['listTitle'];

  initialize() {
    const actualDocument = this.hasListTitleTarget && this.listTitleTarget || document;
    const gaItems = actualDocument.querySelectorAll(`[id^="list-product-item"]`);

    if (gaItems === undefined || gaItems.length === 0) return;

    const currency = gaItems[0].dataset.currency || 'HRK';
    const orderTotal = gaItems[0].dataset.orderTotal || 0;

    if (gaItems.length <= 30) {
      googleTagManager.gaViewItemList(gaItems, currency, orderTotal);
      googleTagManager.ga3ViewItemList(gaItems, currency);
    } else {
      const listsCount = Math.floor(gaItems.length / 30);
      const items = Array.from(gaItems);

      for (let i = 0; i <= listsCount; i++) {
        const sliceIndexOne = i * 30;
        const sliceIndexTwo = sliceIndexOne + 30;

        googleTagManager.gaViewItemList(
          items.slice(sliceIndexOne, sliceIndexTwo), currency, orderTotal
        );
        googleTagManager.ga3ViewItemList(items.slice(sliceIndexOne, sliceIndexTwo), currency);
      }
    }
  }
}
