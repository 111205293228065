import { Controller } from 'stimulus';
import { spreeApiController } from "../packs/scripts/SpreeApiController";
import { notyController } from "../packs/scripts/NotyController";
import {accounting} from "accounting";

export default class extends Controller {
  static targets = ['applyCouponCode', 'removeCouponCode', 'couponForm', 'couponCode', 'errorField', 'errorFieldButtons']

  connect(){
    if (this.hasRemoveCouponCodeTarget) this.hideCouponCodeForm();
  }

  async applyCouponCodeWithCheckout(event) {
    await this.ensureOrderToken();

    const headers = spreeApiController.prepareHeaders();
    const couponCode = this.couponCodeTarget.value.toUpperCase();
    const body = {
      "coupon_code": couponCode
    }

    const source = await fetch(`/api/v2/storefront/cart/apply_coupon_code`, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(body)
    });

    const response = await this.handleApplyCouponResponse(source, couponCode);

    if (response !== 200) return;

    const navigationButton = document.getElementById('navigationSubmitButton');
    navigationButton.form.submit();
  }


  async applyCouponCode(event) {
    if (event.keyCode !== 13 && event.keyCode !== undefined) return ;

    await this.ensureOrderToken();

    const headers = spreeApiController.prepareHeaders();
    const couponCode = this.couponCodeTarget.value.toUpperCase();
    const body = {
      "coupon_code": couponCode
    }

    const source = await fetch(`/api/v2/storefront/cart/apply_coupon_code`, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(body)
    });

    await this.handleApplyCouponResponse(source, couponCode);

    return source;
  }

  async handleApplyCouponResponse(source, couponCode) {
    const json = await source.json();
    const errorElement = this.couponCodeTarget.parentElement.querySelector('.formError');
    const errorElementRegistration = this.errorFieldTarget;
    const errorElementRegistrationButtons = this.errorFieldButtonsTarget;

    if (source.status === 422) {
      if (json.errors.no_user_specified) {
        this.hideFormErrorMessage(errorElement);
        this.showFormErrorMessage(errorElementRegistration, json.errors.no_user_specified[0]);
        errorElementRegistrationButtons.style.display = 'flex';
      } else {
        this.hideFormErrorMessage(errorElementRegistration);
        this.hideFormErrorMessage(errorElementRegistrationButtons);
        this.showFormErrorMessage(errorElement, json.errors.base[0]);
      }
      return source.status;
    }
    if (source.status !== 200) return source.status;

    const orderPromotion = this.findAppliedOrderPromotion(json, couponCode);
    if (orderPromotion === undefined) return;

    this.appendAppliedCoupon(orderPromotion);
    this.hideCouponCodeForm();
    this.updateOrderTotal(json);
    this.hideFormErrorMessage(errorElement);
    this.hideFormErrorMessage(errorElementRegistration);
    this.hideFormErrorMessage(errorElementRegistrationButtons);
    this.clearCouponCodeField();

    notyController.show('success', this.applySuccessMessage());

    return source.status;
  }

  findAppliedOrderPromotion(json, couponCode) {
    const appliedOrderPromotion = json.data.attributes.order_promotions.filter(function(orderPromotion) {
      return orderPromotion.attributes.code == couponCode
    })[0];

    return appliedOrderPromotion?.attributes;
  }

  appendAppliedCoupon(orderPromotion){
    const coupons = document.querySelector('#applied-coupons');

    const newCoupon = document.createElement('div');

    newCoupon.id = 'applied-order-coupon-code';
    newCoupon.classList = 'c-orderOverview__item c-orderOverview__item--coupon';
    newCoupon.innerHTML = this.couponTemplate(orderPromotion);

    coupons.appendChild(newCoupon);
  }

  hideCouponCodeForm() {
    this.couponFormTarget.style.display = "none";
  }

  applySuccessMessage() {
    return this.couponCodeTarget.dataset.applySuccessMessage;
  }

  async removeCouponCode() {
    await this.ensureOrderToken();
    const headers = spreeApiController.prepareHeaders();

    const couponCode = this.removeCouponCodeTarget.dataset.couponCode;

    const source = await fetch(`/api/v2/storefront/cart/remove_coupon_code/${couponCode}`, {
      method: 'DELETE',
      headers: headers
    });

    await this.handleRemoveCouponResponse(source);

    return source;
  }

  async handleRemoveCouponResponse(source) {
    const json = await source.json();
    switch (source.status) {
      case 422:
        break;
      case 404:
        break;
      case 500:
        break;
      case 200:
        notyController.show('success', this.removeSuccessMessage());

        this.removeCoupon();
        this.showCouponCodeForm();
        this.updateOrderTotal(json);

        break;
    }
  }

  showFormErrorMessage(errorElement, message) {
    errorElement.innerText = message;
    errorElement.style.display = "block";
  }

  hideFormErrorMessage(errorElement) {
    errorElement.style.display = 'none';
  }

  clearCouponCodeField() {
    this.couponCodeTarget.value = '';
  }

  removeSuccessMessage() {
    return this.couponCodeTarget.dataset.removeSuccessMessage;
  }

  removeCoupon() {
    const coupon = this.removeCouponCodeTarget.parentElement.parentElement;
    coupon.remove();
  }

  showCouponCodeForm() {
    this.couponFormTarget.style.display = "block";
  }

  couponTemplate(orderPromotion) {
    return `<span class="t-h6 label">Kupon</span>
            <span class="t-h5 value">
            <span> ${orderPromotion.display_amount}
                <br>
                <span class="u-color-alphaBase"> ${orderPromotion.code} </span>
            </span>
            <button type="button"
                    class=" icon"
                    data-order-coupons-target="removeCouponCode"
                    data-coupon-code="${orderPromotion.code}"
                    data-action="click->order-coupons#removeCouponCode">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="22" viewBox="0 0 22 22" width="22">
                    <linearGradient id="a-close" x1="0%" x2="99.800909%" y1="50%" y2="50%">
                      <stop offset="0" stop-color="#28b4b7"></stop>
                      <stop offset=".25" stop-color="#28b4b7"></stop>
                      <stop offset=".75" stop-color="#af6ba9"></stop>
                      <stop offset="1" stop-color="#af6ba9"></stop>
                    </linearGradient>
                    <path d="m1.2 22c-.3 0-.6-.1-.8-.3-.4-.4-.4-1.1 0-1.6l9-9.1-9.1-9c-.4-.4-.4-1.1 0-1.6.5-.4 1.2-.4 1.6 0l9.1 9 9-9.1c.4-.4 1.1-.4 1.6 0 .4.4.4 1.1 0 1.6l-9 9.1 9.1 9c.4.4.4 1.1 0 1.6-.4.4-1.1.4-1.6 0l-9.1-9-9 9.1c-.2.2-.5.3-.8.3z" fill="url(#a-close)"></path>
                  </svg>
            </button>
            </span>`
  }

  updateOrderTotal(json) {
    const navBarCart = document.getElementById('js-navbar-cart-button-display-total');
    navBarCart.innerHTML = json.meta.price_total;

    const orderTotal = document.getElementById('summary-order-total-sidebar');
    orderTotal.innerHTML = json.meta.price_total;
  }

  async ensureOrderToken() {
    if (spreeApiController.orderToken.length === 0) {
      const source = await fetch(`/ensure_cart`, {method: 'POST'});
      const data = await source.json();

      spreeApiController.orderToken = data.token
    }
  }
}
