class ExpandProductDetailsController {
  constructor() {
    this.DOM = {
      plusIcon: ".icon__plus",
      minusIcon: ".icon__minus",
      hideIconClass: "icon--hidden",
      body: "#product-description",
      productDescription: ".c-descriptionList",
      bodyHiddenClass: "body--hidden",
    };
  }
  init() {
    const descriptions = document.querySelectorAll(this.DOM.productDescription);
    for (let description of descriptions) {
      const plusIcon = description.querySelector(this.DOM.plusIcon);
      const minusIcon = description.querySelector(this.DOM.minusIcon);
      const body = description.querySelector(this.DOM.body);

      if (plusIcon && minusIcon && body) {
        plusIcon.addEventListener("click", () => {
          body.classList.remove(this.DOM.bodyHiddenClass);
          plusIcon.classList.add(this.DOM.hideIconClass);
          minusIcon.classList.remove(this.DOM.hideIconClass);
        });

        minusIcon.addEventListener("click", () => {
          body.classList.add(this.DOM.bodyHiddenClass);
          plusIcon.classList.remove(this.DOM.hideIconClass);
          minusIcon.classList.add(this.DOM.hideIconClass);
        });
      }
    }
  }
}

export let expandProductDetailsController =
  new ExpandProductDetailsController();
