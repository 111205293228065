import Choices from 'choices.js'

class RadioTabsController {
  constructor() {
    this.DOM = {
      container: '.js-radioTabs',
      trigger: '.js-radioTabs__trigger',
      target: '.js-radioTabs__target',
    }
  }
  init() {
    if (document.querySelector(this.DOM.trigger) == null) return
    let containers = document.querySelectorAll(this.DOM.container)

    containers.forEach(container => {
      let radios = container.querySelectorAll(this.DOM.trigger)
      let targets = container.querySelectorAll(this.DOM.target)

      targets.forEach(target => {
        target.style.display = 'none'
      })

      radios.forEach(radio => {
        statechange(radio)

        radio.addEventListener('change', ()=> {
          targets.forEach(target => {
            target.style.display = 'none'
          })
          statechange(radio)
        })
      })
    })

    function statechange(radio) {
      let dataName = radio.dataset.target
      if (radio.checked == true) {
        let target = document.getElementById(dataName)
        target.style.display = 'block'
      }
    }

  }
}

export let radioTabsController = new RadioTabsController();
