import { spreeApiController } from "../SpreeApiController";
import { notyController } from "../NotyController";

const buyingListItemBasePath = '/api/v2/storefront/account/buying_list_items';

class BuyingListItem {
  async updateItemQuantity(quantity, buyingListItemId) {
    await spreeApiController.ensureTokens();
    const headers = spreeApiController.prepareHeaders();
    const updateUrl = `${buyingListItemBasePath}/${buyingListItemId}`;

    const body = this.updateBodyJson(quantity);
    const result = await fetch(updateUrl, {
      method: 'PUT',
      headers: headers,
      body: body
    });

    if (result.status === 200) {
      notyController.show('success', 'Količina proizvoda u popisu ažurirana');
    } else {
      // console.log('something went wrong')
    }
  }

  updateBodyJson(quantity) {
    const body = {
      buying_list_item: { quantity: quantity }
    }
    return JSON.stringify(body);
  }

  async deleteItem(buyingListItemId) {
    await spreeApiController.ensureTokens();
    const headers = spreeApiController.prepareHeaders();
    const deleteUrl = `${buyingListItemBasePath}/${buyingListItemId}`;

    const result = await fetch(deleteUrl, {
      method: 'DELETE',
      headers: headers
    });
    return result;
  }
}

export const buyingListItem = new BuyingListItem();
