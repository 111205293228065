import Choices from 'choices.js'

class SelectController {
  constructor() {
    this.DOM = {
      target: '.js-customSelect select',
    }
  }
  init() {
    if (document.querySelector(this.DOM.target) == null) return

    new Choices(this.DOM.target, {
      searchEnabled: false,
      searchChoices: false,
      itemSelectText: '',
    });
  }
}

export let selectController = new SelectController();
