import { Controller } from 'stimulus';
import MicroModal from 'micromodal';
import { spreeApiController } from "../packs/scripts/SpreeApiController";
import { notyController } from "../packs/scripts/NotyController";
import { ErrorCodes } from "../packs/scripts/errorCodes";
import { cart } from '../packs/scripts/helpers/Cart';
import { orderLocationMicroModal } from "../packs/scripts/helpers/OrderLocationMicroModal";
import AddToListController from "../packs/scripts/AddToListController";

export default class extends Controller {
  static targets = ['rename', 'listName', 'copy']

  showRenameMicroModal() {
    MicroModal.show('rename-list', {
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      debugMode: true
    });
  }

  async copyToCart() {
    await spreeApiController.ensureTokens();
    const headers = spreeApiController.prepareHeaders();
    const id = this.copyTarget.dataset.id;
    const url = `/api/v2/storefront/account/buying_lists/${id}/copy_to_cart`;

    const source = await fetch(url, {
      method: 'POST',
      headers: headers
    });

    await this.handleResponse(source);
  }

  async handleResponse(source) {
    switch (source.status) {
      case 422:
        const error = await source.json();
        if (error.errors.code === ErrorCodes.invalidStockLocation) {
          orderLocationMicroModal.open();
        }
        break;
      case 200:
        notyController.show('success', 'Popis za kupovinu je dodan u košaricu');
        const data = await source.json();
        cart.refreshTotal(data);
        break;
    }
  }

  showCopyFromOrderMicroModal() {
    MicroModal.show('copy-from-order', {
      onShow: modal => {
        if (modal.id === 'copy-from-order') {
          let addToListController = new AddToListController(modal);
          addToListController.init();
        }
      },
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      debugMode: true
    });
  }

}
