import { Controller } from 'stimulus';
import { spreeApiController } from '../packs/scripts/SpreeApiController';
import { notyController } from "../packs/scripts/NotyController";

export default class extends Controller {
  async cancel(e) {
    await spreeApiController.ensureTokens();

    const headers = spreeApiController.prepareHeaders();
    const orderNumber = e.target.dataset.orderNumber;
    const cancelUrl = `/api/v2/storefront/account/orders/${orderNumber}/cancel`;

    const result = await fetch(cancelUrl, {
      method: 'PUT',
      headers: headers,
    });

    if (result.status === 200) {
      e.target.style.display = 'none';

      notyController.show('success', e.target.dataset.cancelMessage);
    }
  }
}
