class NamingConverter {
  toCamelCase(str){
    const regExp = /[-_]\w/ig;
    return str.replace(regExp,(match) => {
      return match[1].toUpperCase();
    });
  }
}

export const namingConverter = new NamingConverter();
