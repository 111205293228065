import { disableBodyScroll } from 'body-scroll-lock';

class LoaderController {
  start() {
    let target = document.querySelector('.js-page-loader')
    target.classList.remove('is-hidden')
    disableBodyScroll(target)
  }
  close() {
    let target = document.querySelector('.js-page-loader')
    target.classList.add('is-hidden')
    disableBodyScroll(target)
  }
}

export let loaderController = new LoaderController();
