import { Controller } from 'stimulus';
import { spreeApiController } from '../packs/scripts/SpreeApiController';
import { notyController } from "../packs/scripts/NotyController";
import { googleTagManager } from "../packs/scripts/helpers/GoogleTagManager";

export default class extends Controller {
  static targets = ['newList', 'listId', 'newName', 'copyOrder']
  static variantId;

  fetchVariant(e) {
    Controller.variantId = e.detail.variant_id;
  }

  async addVariantToBuyingList() {
    await spreeApiController.ensureTokens();
    const body = this.checkParamsAndPrepareBody({ variant: { id: Controller.variantId } });
    await this.addVariant(body, Controller.variantId);
  }

  checkParamsAndPrepareBody(object) {
    const id = this.calculateId();
    const name = this.calculateName(id);

    if (id === '' && name === '') {
      notyController.show('info', 'Molimo popunite sve podatke za popis za kupovinu.');
    } else {
      return JSON.stringify({
        buying_list: { id: id, name: name }, ...object
      })
    }
  }

  calculateId() {
    return this.listIdTarget.value !== 'new' ? parseInt(this.listIdTarget.value) : '';
  }

  calculateName(id) {
    return typeof id === 'number' ? '' : this.newListTarget.value;
  }

  async addVariant(body, variantId = null) {
    const headers = spreeApiController.prepareHeaders();
    const url = '/api/v2/storefront/account/buying_lists/add_variant';
    const source = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body
    });

    await this.handleResponse(source, variantId);
  }

  async handleResponse(source, variantId = null) {
    switch (source.status) {
      case 422:
        notyController.show('info', 'Molimo popunite sve podatke');
        break;
      case 403:
        notyController.show('info', 'Prijavite se da bi mogli dodat proizvod u popis za kupovinu');
        break;
      case 200:
        notyController.show('success', 'Uspješno ste dodali proizvod u popis za kupovinu.');

        if (variantId) {
          const variant = document.getElementById(`buying-list-data-button-[${variantId}]`);

          if (variant) googleTagManager.gaAddToBuyinglist(variant.dataset, 1);
        }

        break;
    }
  }

  async renameList() {
    await spreeApiController.ensureTokens();
    const headers = spreeApiController.prepareHeaders();
    const id = this.newNameTarget.dataset.id;

    const url = `/api/v2/storefront/account/buying_lists/${id}/rename`;
    const name = this.newNameTarget.value;

    if (name === '') {
      return notyController.show('info', 'Molimo unesite novo ime');
    }
    const body = JSON.stringify({
      name: name
    });

    const source = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: body
    });

    await this.handleRenameResponse(source, name);
  }

  async handleRenameResponse(source, name) {
    switch (source.status) {
      case 422:
        const error = await source.json();
        if (error.error == 'Validacija nije uspjela: Name je već zauzet') {
          notyController.show('info', 'Popis s navedenim imenom već postoji');
        } else {
          notyController.show('info', 'Molimo unesite ispravno ime');
        }
        break;
      case 200:
        notyController.show('success', 'Ime popisa promijenjeno!');
        const data = await source.json();
        const buyingList = document.getElementById('buying-list-name');
        buyingList.innerHTML = data.data.attributes.name;
        break;
    }
  }

  async copyFromOrder() {
    await spreeApiController.ensureTokens();
    const headers = spreeApiController.prepareHeaders();
    const orderNumber = this.copyOrderTarget.dataset.orderNumber;
    const url = `/api/v2/storefront/account/buying_lists/copy_from_order`;
    const body = this.checkParamsAndPrepareBody({ order: { number: orderNumber } });
    const cartOrder = document.getElementById('ga-cart-order');
    const source = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body
    });
    if (source.status === 200) {
      notyController.show('success', 'Košarica je dodana u popis za kupovinu!');

      if (cartOrder) {
        googleTagManager.gaAddToBuyinglist(cartOrder.dataset, 1, cartOrder.dataset.orderTotal);
      }
    }
  }
}
