import MicroModal from "micromodal";
// import { orderLocationController } from "../OrderLocationController";
import OrderLocationControllerV2 from "../OrderLocationControllerV2";

class OrderLocationMicroModal {
  open() {
    MicroModal.show('order-location-choice-v2', {
      onShow: modal => {
        if (modal.id === 'order-location-choice-v2') {
          if (!this.orderLocationControllerV2) {
            this.orderLocationControllerV2 = new OrderLocationControllerV2(
              modal
            );
          }
          this.orderLocationControllerV2.init('#autoComplete');

        }
      },
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      debugMode: true
    });
  }
}

export const orderLocationMicroModal = new OrderLocationMicroModal();
