import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

class ProductSwitchSliderController {
  constructor() {
    this.DOM = {
      sliderClass: '.js-productSwitchSlider',
      sliderClassInit: '.js-productSwitchSlider__init',
    }
  }

  init() {
    if (document.querySelector(this.DOM.sliderClass) !== null) {
      this.slider();
    } else {
      console.log(`${this.DOM.sliderClass} does not exist in the DOM!`);
    }
  }

  slider() {

    const sliders = document.querySelectorAll(this.DOM.sliderClass);
    if (sliders == null) return

    sliders.forEach(slider => {
      let sliderInit = slider.querySelector(this.DOM.sliderClassInit)
      let sliderInst = new Swiper(sliderInit, {
        loop: false,
        watchOverflow: true,
        watchSlidesVisibility: true,
        centerInsufficientSlides: true,
        spaceBetween: 0,
        slidesPerView: 1,
        slidesPerGroup: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 2,
        },
        breakpoints: {
          // when window width is >=
          519: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          992: {
            slidesPerView: 2,
            slidesPerGroup: 1,
          },
          1024: {
            slidesPerView: 'auto',
            slidesPerGroup: 1,
          },
        },
      });
    });


  }

}

// export default productSwitchSliderController;
export let productSwitchSliderController = new ProductSwitchSliderController();
