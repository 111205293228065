import { Controller } from 'stimulus';
import { notyController } from "../packs/scripts/NotyController";
import { buyingListItem } from "../packs/scripts/helpers/BuyingListItem";

let debounce = require('lodash.debounce');

export default class extends Controller {

  initialize() {
    this.updateBtnQuantity = debounce(this.updateBtnQuantity, 600).bind(this);
  }

  async updateBtnQuantity(e) {
    const quantity = e.detail.btnQty;

    if (quantity <= 0 || !Number.isInteger(parseInt(quantity)))
      return notyController.show('info', 'Neispravna količina proizvoda');

    await buyingListItem.updateItemQuantity(quantity, e.detail.btnItemId);
  }
}
