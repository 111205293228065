import { Controller } from 'stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  static targets = ['promotionData'];

  trackPromotion = () => {
    let promo = '';
    let list = '';

    if (this.hasPromotionDataTarget) {
      promo = this.promotionDataTarget.dataset.fromPromotion || '';
      list = this.promotionDataTarget.dataset.fromList || '';
    }

    Cookies.set('medika_promo', promo);
    Cookies.set('medika_list', list);
  }

  resetPromotion = () => {
    Cookies.remove('medika_promo');
    Cookies.remove('medika_list');
  }
}
