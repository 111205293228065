import { Controller } from 'stimulus';

import { buyingListItem } from '../packs/scripts/helpers/BuyingListItem';
import { notyController } from "../packs/scripts/NotyController";

let debounce = require('lodash.debounce');

export default class extends Controller {
  static targets = ['quantityToAdd', 'variant'];

  initialize() {
    this.buyingListItemId = this.quantityToAddTarget.dataset.buyingListItemId;

    this.triggerQuantityEvent = debounce(this.triggerQuantityEvent, 300).bind(this);
    this.updateItemQuantity = debounce(this.updateItemQuantity, 600).bind(this);
  }

  triggerQuantityEvent() {
    const event = new CustomEvent("changeTriggerred", {
      detail: {
        btnItemId: this.buyingListItemId,
        btnQty: this.quantityToAddTarget.value
      }
    });

    window.dispatchEvent(event);
  }

  async updateItemQuantity() {
    const quantity = this.quantityToAddTarget.value;

    if (quantity <= 0 || !Number.isInteger(parseInt(quantity)))
      return notyController.show('info', 'Neispravna količina proizvoda');

    await buyingListItem.updateItemQuantity(quantity, this.buyingListItemId);
  }

  async deleteItem() {
    const result = await buyingListItem.deleteItem(this.buyingListItemId);

    if (result.status === 204) {
      notyController.show('success', 'Proizvod je obrisan iz popisa!');
      this.variantTarget.remove();
    } else {
      //console.log('something went wrong')
    }
  }
}
