import { addressAttributes, addressList } from "../AddressList";
import { namingConverter } from "../NamingConverter";
import { notyController } from "../../NotyController";
import { validationController } from "../../ValidationController";
import { visibility } from "../Visibility";

class AddressResponseHandler {
  async create(addressController, source, body, elementTarget) {
    const message = addressController.listAddressTarget.dataset.successCreatedNotificationMessage;

    switch (source.status) {
      case 422:
        const error = await source.json();
        addressList.errorList(error, elementTarget);
        break;
      case 500:
        break;
      case 200:
        const responseData = await source.json();
        notyController.show('info', message);

        let clone = document.querySelector('#delivery_address__row').cloneNode(true);
        this.addNewAddressRow(clone, this.addressAttributesFromResponse(responseData));

        visibility.resetForm([document.querySelector('#new-address-form')]);
        visibility.hideTargets([addressController.formcontainerTarget]);
        visibility.hideTargets([addressController.addnewsectionTarget]);

        addressList.displayButtons(addressController);
        validationController.init();
        break;
    }
  }

  async update(addressController, source, body, index, elementTarget) {
    const message = addressController.addressRowTarget.dataset.successUpdatedNotificationMessage;

    switch (source.status) {
      case 422:
        const error = await source.json();
        addressList.errorList(error, elementTarget)
        break;
      case 500:
        break;
      case 200:
        const responseData = await source.json();
        notyController.show('info', message);

        visibility.hideTargets([addressController.editFormContainerTargets[index]]);

        const address_attributes = this.addressAttributesFromResponse(responseData)
        addressList.updateHiddenEditForm(addressController, address_attributes, index);
        addressList.updateAddressList(addressController, address_attributes, index);

        addressList.displayButtons(addressController);
        break;
    }
  }

  async destroy(addressController, source, addressId) {
    const message = addressController.addressRowTarget.dataset.successDeletedNotificationMessage;

    switch (source.status) {
      case 422:
        const error = await source.json();
        notyController.show('error', error.errors.base);
        break;
      case 500:
        break;
      case 204:
        notyController.show('info', message);

        addressList.removeAddress(addressController, addressId);
        addressList.displayButtons(addressController);
        break;
    }
  }

  // Helper methods
  addNewAddressRow(addressRow, data) {
    visibility.showTargets([addressRow], 'block')

    addressRow.id = 'delivery_address_' + data.id + '_row';

    // String with full info of address
    const addressFullInfo = addressRow.querySelector('#addressFullInfo');
    addressFullInfo.textContent = addressList.addressFullInfo(data);

    // Buttons - Update, delete
    addressRow.querySelector('[data-address-target="addressEdit"]').setAttribute('data-delivery-address-id', data.id);
    addressRow.querySelector('[data-address-target="addressDelete"]').setAttribute('data-delivery-address-id', data.id);

    // Input fields for edit form
    addressAttributes.forEach(function (attr) {
      let camel_case_attr = namingConverter.toCamelCase(attr);
      addressRow
        .querySelector(`[data-address-target=${camel_case_attr}]`)
        .setAttribute('value', data[camel_case_attr]);
    });

    const htmlAddressList = document.querySelector('.c-addressTable__body');
    htmlAddressList.appendChild(addressRow);
  }

  addressAttributesFromResponse(response) {
    const attributes = response.data.attributes;

    return {
      id: response.data.id,
      firstname: attributes.firstname,
      lastname: attributes.lastname,
      phone: attributes.phone,
      city: attributes.address.attributes.city,
      zipcode: attributes.address.attributes.zipcode,
      streetName: attributes.address.attributes.street_name,
      houseNumber: attributes.address.attributes.house_number
    }
  }
}

export const addressResponseHandler = new AddressResponseHandler();
