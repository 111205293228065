import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

class ProductSliderController {
  constructor() {
    this.DOM = {
      sliderClass: '.js-productsSlider',
      sliderClassInit: '.js-productsSlider__init',
    }
  }

  init() {
    if (document.querySelector(this.DOM.sliderClass) !== null) {
      this.slider();
    } else {
      console.log(`${this.DOM.sliderClass} does not exist in the DOM!`);
    }
  }

  slider() {

    const sliders = document.querySelectorAll(this.DOM.sliderClass);
    if (sliders == null) return

    sliders.forEach(slider => {
      let sliderInit = slider.querySelector(this.DOM.sliderClassInit)
      let sliderInst = new Swiper(sliderInit, {
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".c-productCardsSlider .swiper-button-next",
          prevEl: ".c-productCardsSlider .swiper-button-prev",
        },
        watchOverflow: true,
        watchSlidesVisibility: true,
        centerInsufficientSlides: true,
        spaceBetween: 0,
        slidesPerView: 1,
        slidesPerGroup: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 3,
        },
        breakpoints: {
          // when window width is >=
          519: {
            slidesPerView: 2,
            slidesPerGroup: 1,
          },
          992: {
            slidesPerView: 'auto',
            slidesPerGroup: 1,
          },
        },
      });
    });


  }

}

// export default productSliderController;
export let productSliderController = new ProductSliderController();
