import tippy from 'tippy.js';

class TooltipController {
  constructor() {
    this.DOM = {
      trigger: '.js-tooltip',
    }
  }
  init() {
    tippy.setDefaultProps({
      theme: 'google',
      arrow: true,
      trigger: 'click',
    })
    const els = document.querySelectorAll(this.DOM.trigger)
    tippy(els)

    window.addEventListener('scroll', () => {
      for (const popper of document.querySelectorAll('.tippy-popper')) {
        const instance = popper._tippy
        if (instance.state.visible) {
          instance.popperInstance.disableEventListeners()
          instance.hide()
        }
      }
    })
  }
}

export let tooltipController = new TooltipController();
