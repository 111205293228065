import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

class FreeScrollSliderController {
  constructor() {
    this.DOM = {
      sliderClass: '.js-freeScrollSlider',
      sliderClassInit: '.js-freeScrollSlider__init',
    }
  }

  init() {
    if (document.querySelector(this.DOM.sliderClass) !== null) {
      this.slider();
    } else {
      console.log(`${this.DOM.sliderClass} does not exist in the DOM!`);
    }
  }

  slider() {

    const sliders = document.querySelectorAll(this.DOM.sliderClass);
    if (sliders == null) return

    sliders.forEach(slider => {
      let sliderInit = slider.querySelector(this.DOM.sliderClassInit)
      let sliderInst = new Swiper(sliderInit, {
        loop: false,
        watchOverflow: true,
        watchSlidesVisibility: true,
        centerInsufficientSlides: false,
        spaceBetween: 15,
        slidesPerView: 'auto',
        freeMode: true,
      });
    });


  }

}

// export default freeScrollSliderController;
export let freeScrollSliderController = new FreeScrollSliderController();
