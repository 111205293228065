import { Controller } from 'stimulus';
import { spreeApiController } from '../packs/scripts/SpreeApiController';
import Cookies from 'js-cookie';

export default class extends Controller {
  static targets = ['tickerContainer'];

  async initialize() {
    await this.ensureTokens();

    const closedTickers = Cookies.get('closed_tickers');
    const headers = spreeApiController.prepareHeaders();

    const response = await fetch(`/api/v2/storefront/tickers?closed_tickers=${closedTickers}`, {
      method: 'GET',
      headers: headers
    });

    if(response.status !== 200) return;

    const tickersPartial = await response.json();

    this.tickerContainerTarget.innerHTML = tickersPartial.tickers_partial;
  }

  async ensureTokens() {
    if (spreeApiController.oauthToken.length === 0) {
      const source = await fetch(`/api_tokens`, {method: 'GET'});
      const data = await source.json();

      spreeApiController.oauthToken = data.oauth_token;
    }
  }
}
