class SpreeApiController {
  orderToken = '';
  oauthToken = '';

  prepareHeaders() {
    let headers = {};

    headers['Accept'] = 'application/json'
    headers['Content-Type'] = 'application/json'
    headers['X-Spree-Order-Token'] = this.orderToken;
    headers['Authorization'] = 'Bearer ' + this.oauthToken;

    return headers;
  }

  async ensureTokens() {
    if (this.oauthToken.length === 0) {
      const source = await fetch(`/api_tokens`, {method: 'GET'});
      const data = await source.json();

      this.oauthToken = data.oauth_token;
    }
  }
}

export let spreeApiController = new SpreeApiController();
