import { Controller } from 'stimulus';
import MicroModal from 'micromodal';
// import { orderLocationController } from "../packs/scripts/OrderLocationController";
import OrderLocationControllerV2 from "../packs/scripts/OrderLocationControllerV2";
import { selectAddressController } from "../packs/scripts/SelectAddressController";

export default class extends Controller {
  static targets = ['currentOrder', 'delivery', 'store'];

  initialize() {
    if (!this.hasDeliveryTarget || !this.hasStoreTarget) return;

    if (this.deliveryTarget.dataset.otcRedirect) {
      this.deliveryTarget.checked = true;
      this.storeTarget.checked = false;
      this.storeTarget.disabled = true;
    } else if (this.deliveryTarget.dataset.otcPopup == 'true') {
      this.deliveryTarget.checked = false;
      this.storeTarget.checked = true;
    }
  }

  pickupInStore = (e) => {
    e.preventDefault();

    this.storeTarget.checked = true;
  }

  buttonActions = (e) => {
    e.preventDefault();
  }

  otcItemsAlertPopup = () => {
    if (this.deliveryTarget.dataset.otcRedirect) return;

    document.getElementById('store').checked = true;

    MicroModal.show('otc-items-alert-popup', {
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      debugMode: true
    });
  }

  openMicroModal() {
    MicroModal.show('order-location-choice-v2', {
      onShow: modal => {
        if (modal.id === 'order-location-choice-v2') {
          if (!this.orderLocationControllerV2) {
            this.orderLocationControllerV2 = new OrderLocationControllerV2(
              modal
            );
          }
          this.orderLocationControllerV2.init('#autoComplete');
          selectAddressController.init();

        }
      },
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      debugMode: true
    });
  }
}
