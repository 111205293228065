class Cart {
  refreshTotal(data) {
    const meta = data.meta;
    const cart = document.getElementById('navbar-cart-button-small');
    const itemTotal = document.getElementById('js-navbar-cart-button-item-total');
    const displayTotal = document.getElementById('js-navbar-cart-button-display-total');

    displayTotal.innerHTML = meta.price_total;
    itemTotal.innerHTML = meta.line_item_total;

    cart.onclick = () => true;
  }
}

export const cart = new Cart();
