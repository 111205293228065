import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['dropdown'];

  onChange() {
    const value = this.dropdownTarget.value;
    document.querySelector(`#${value}`)?.scrollIntoView({behavior: 'smooth'});
  }
}
