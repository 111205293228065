class PasswordToggleController {
  constructor() {
    this.DOM = {
      container: '.js-password-toggle',
      input: '.js-password-toggle__input',
      btn: '.js-password-toggle__btn',
    }
  }
  init() {
    if (document.querySelector(this.DOM.container) !== null) {
      this.event();
    }
  }
  event() {
    let containers = document.querySelectorAll(this.DOM.container)
    containers.forEach(container => {
      let btn = container.querySelector(this.DOM.btn)
      let input = container.querySelector(this.DOM.input)
      btn.addEventListener('click', () => {
        this.toggle(input, container)
      });

    });
  }
  toggle(input, container) {
    container.classList.contains('state--visible') ? this.hide(input, container) : this.show(input, container)
  }

  show(input, container) {
    container.classList.add('state--visible')
    input.type = "text";
  }

  hide (input, container) {
    container.classList.remove('state--visible')
    input.type = "password";
  }
}


export let passwordToggleController = new PasswordToggleController();
