class Visibility {
  showTargets(elements, displayValue) {
    if (elements === undefined) return;

    elements.forEach(el => {
      el.style.display = displayValue;
    });
  }

  hideTargets(elements) {
    if (elements === undefined) return;

    elements.forEach(el => {
      el.style.display = 'none';
    });
  }

  resetForm(elements) {
    if (elements === undefined) return;

    elements.forEach(el => {
      el.reset();
    });
  }
}

export const visibility = new Visibility();
