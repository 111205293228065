import Choices from 'choices.js'

class SelectAddressController {
  constructor() {
    this.DOM = {
      target: '.js-customSelectAddress select',
    }
  }
  init() {
    const elems = document.querySelectorAll(this.DOM.target);
    if (elems.length === 0) return

    elems.forEach(elem => {
      new Choices(elem, {
        searchEnabled: false,
        searchChoices: false,
        itemSelectText: "",
      });
    })
  }
}

export let selectAddressController = new SelectAddressController();
