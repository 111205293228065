class QuantityController {
  constructor() {
    this.DOM = {
      containers: '.js-quantity',
      input: '.js-quantity__input',
      btnUp: '.js-quantity__up',
      btnDown: '.js-quantity__down',
    }
  }

  init() {
    if (this.DOM.containers !== null) {
      this.inputEvents();
    } else {
      console.error(`${this.DOM.containers} does not exist in the DOM!`);
    }
  }
  inputEvents() {
    let containers = document.querySelectorAll(this.DOM.containers);

    containers.forEach((container) => {
      let input = container.querySelector(this.DOM.input)
      let btnUp = container.querySelector(this.DOM.btnUp)
      let btnDown = container.querySelector(this.DOM.btnDown)
      let step = input.getAttribute('step')

      if (btnUp != null) {
        btnUp.addEventListener('click', e => {
          this.eventUp(input, step);
        });
      }

      if (btnDown != null) {
        btnDown.addEventListener('click', e => {
          this.eventDown(input, step);
        });
      }
    })
  }

  eventUp(input, step) {
    input.stepUp(step);
  }
  eventDown(input, step) {
    input.stepDown(step)
  }
}

export let quantityController = new QuantityController();
