class PageDescriptionController {
  constructor() {
    this.DOM = {
      pageDescriptionContainer: ".js-page-description__text",
      pageDescriptionTextContainer: ".js-page-description__container",
      pageDescriptionExpandButtonContainer: ".js-page-description__show-more",
      pageDescriptionExpandButton: ".js-page-description__show-more-button",
      pageDescriptionReduceButton: ".js-page-description__show-less-button",
    };
  }
  init() {
    const pageDescriptionContainer = document.querySelector(
      this.DOM.pageDescriptionContainer
    );
    const pageDescriptionTextContainer = document.querySelector(
      this.DOM.pageDescriptionTextContainer
    );
    const pageDescriptionExpandButtonContainer = document.querySelector(
      this.DOM.pageDescriptionExpandButtonContainer
    );
    const pageDescriptionExpandButton = document.querySelector(
      this.DOM.pageDescriptionExpandButton
    );

    const pageDescriptionReduceButton = document.querySelector(
      this.DOM.pageDescriptionReduceButton
    );

    if (
      pageDescriptionContainer &&
      pageDescriptionTextContainer &&
      pageDescriptionExpandButtonContainer &&
      pageDescriptionExpandButton &&
      pageDescriptionReduceButton
    ) {
      pageDescriptionExpandButton.addEventListener("click", () => {
        pageDescriptionExpandButtonContainer.style.display = "none";
        pageDescriptionReduceButton.style.display = "block";

        pageDescriptionContainer.style.height = "unset";
      });

      pageDescriptionReduceButton.addEventListener("click", () => {
        pageDescriptionExpandButtonContainer.style.display = "block";
        pageDescriptionReduceButton.style.display = "none";

        pageDescriptionContainer.style.height = "";
      });
    }
  }
}

export const pageDescriptionController = new PageDescriptionController();