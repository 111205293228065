
/**
 * Marker Template
 * @param {obj} data - property data/json
 */
function markerTmpl(data) {

  const url = encodeURIComponent(data.address)

  return `<article class="c-markerBox">
    <div class="c-markerBox__wrap">
      <div class="c-markerBox__grid">
        <div class="c-markerBox__main">
          <span class="c-markerBox__title">${data.title}</span>
          <address class="c-markerBox__address">
            <span class="t-small">Adresa: <b>${data.address}</b></span>
          </address>
          <a target="_blank" class="e-btn e-btn--primaryOutline"
          href="https://www.google.com/maps/place/${url}/">
          <span class="e-btn__text">Upute</span>
          </a>
        </div>
      </div>
    </div>
  </article>`;
}

export default markerTmpl;
