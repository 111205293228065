import Swiper, { Navigation, Pagination, Thumbs } from "swiper";
Swiper.use([Navigation, Pagination, Thumbs]);

class ProductGalleryController {
  constructor() {
    this.DOM = {
      sliderClass: ".js-productGallery",
      sliderClassInit: ".js-productGallery__init",
    };
  }

  init() {
    if (document.querySelector(this.DOM.sliderClass) !== null) {
      this.slider();
    } else {
      console.log(`${this.DOM.sliderClass} does not exist in the DOM!`);
    }
  }

  slider() {
    const sliders = document.querySelectorAll(this.DOM.sliderClass);
    if (sliders == null) return;

    sliders.forEach((slider) => {
      let sliderInit = slider.querySelector(this.DOM.sliderClassInit);
      let thumbsSwiper;

      if (slider.dataset.thumbs) {
        thumbsSwiper = new Swiper(".thumbsSwiper", {
          spaceBetween: 20,
          slidesPerView: 4,
          freeMode: true,
          watchSlidesProgress: true,
        });
      }

      new Swiper(sliderInit, {
        loop: false,
        centerInsufficientSlides: false,
        spaceBetween: 10,
        slidesPerView: 1,
        centeredSlides: true,
        navigation: {
          nextEl: ".c-productGallery .swiper-button-next",
          prevEl: ".c-productGallery .swiper-button-prev",
        },
        thumbs: {
          swiper: thumbsSwiper,
        },
        // slidesPerGroup: 1,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        //   // dynamicBullets: true,
        //   // dynamicMainBullets: 2,
        // },
      });
    });
  }
}

// export default productGalleryController;
export let productGalleryController = new ProductGalleryController();
