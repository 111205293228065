import Swal from 'sweetalert2'
class NotyController {
  constructor() {

    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

  }

  show(type, title) {

    this.Toast.fire({
      icon: type,
      title: title
    })

  }
}


export let notyController = new NotyController();
