import { Controller } from 'stimulus';
import { googleTagManager } from "../packs/scripts/helpers/GoogleTagManager";

export default class extends Controller {
  static targets = ['productViewInfo'];

  initialize() {
    if (!this.hasProductViewInfoTarget) return;

    googleTagManager.gaViewProduct(this.productViewInfoTarget.dataset);
  }
}
