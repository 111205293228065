class ShowHideController {
  constructor() {
    this.DOM = {
      container: '.js-toggleDisplay',
      trigger: '.js-toggleDisplay__trigger',
      target: '.js-toggleDisplay__target',
    }
  }
  init() {
    if (document.querySelector(this.DOM.container) == null) return
    let containers = document.querySelectorAll(this.DOM.container)

    containers.forEach(container => {
      let trigger = container.querySelector(this.DOM.trigger)
      let target = container.querySelector(this.DOM.target)
      let form = container.querySelector('form')

      trigger.addEventListener('change', ()=> {
        if (form) form.reset()
        target.style.display = (target.style.display === "none") ? "block" : "none";
      })
    })

  }
}

export let showHideController = new ShowHideController();
