document.addEventListener("DOMContentLoaded", function () {
  const productPageRedesignSection = document.querySelector(
    ".product-page-redesign"
  );
  const category = document.querySelector(".product-page-redesign__category");
  const openBtn = document.querySelector(
    ".product-page-redesign__category-btn__open"
  );
  const closeBtn = document.querySelector(
    ".product-page-redesign__category-btn__close"
  );

  const toggleSection = (isOpen) => {
    // Toggle button visibility
    openBtn.style.display = isOpen ? "none" : "block";

    // Toggle section and category sizes
    productPageRedesignSection.style.height = isOpen ? "100%" : "40px";
    category.style.width = isOpen ? "100%" : "85%";

    // Apply transitions
    const transitionStyle = "all 0.3s ease-in-out";
    productPageRedesignSection.style.transition = transitionStyle;
    category.style.transition = transitionStyle;
  };

  openBtn.addEventListener("click", () => toggleSection(true));
  closeBtn.addEventListener("click", () => toggleSection(false));
});
