
import MicroModal from 'micromodal';
import { orderLocationController } from './OrderLocationController';
import OrderLocationControllerV2 from './OrderLocationControllerV2';
import { selectAddressController } from './SelectAddressController';
import AddToListController  from './AddToListController';
import VideoModalController  from './VideoModalController';

class ModalController {
  constructor() {
    // this.DOM = {
    //   target: '.js-customSelectAddress select',
    // }
  }
  init() {
    MicroModal.init({
      onShow: modal => {
        console.info(`${modal.id} is shown`)
        if (modal.id == 'order-location-choice') {
          // setTimeout(() => {
            selectAddressController.init()
            orderLocationController.init('.js-location-search')
            // }, 2000);
        }
         if (modal.id == "order-location-choice-v2") {
           // setTimeout(() => {
           selectAddressController.init();
           if (!this.orderLocationControllerV2) {
             this.orderLocationControllerV2 = new OrderLocationControllerV2(
               modal
             );
           }
           this.orderLocationControllerV2.init(".js-location-search");
           // }, 2000);
         }
          if (modal.id == 'add-to-list') {
            let addToListController = new AddToListController(modal);
            addToListController.init()
          }

          if (modal.id.includes('video-youtube')) {
            let videoModalController = new VideoModalController(modal, modal.id);
            videoModalController.init()
          }

          // if (modal.id == 'video-mp4') {
          if (modal.id.includes('video-mp4')) {
            let videoModalController = new VideoModalController(modal, modal.id);
            videoModalController.init()
          }

          // if (modal.id == 'video-vimeo') {
          if (modal.id.includes('video-vimeo')) {
            let videoModalController = new VideoModalController(modal, modal.id);
            videoModalController.init()
          }
      },
      // onClose: modal => {
      //   console.info(`${modal.id} is hidden`)
      // },
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      debugMode: true
    });

  }
}

export let modalController = new ModalController();
