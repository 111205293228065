import Pristine from 'pristinejs'
import axios from 'axios';
// import Qs from 'qs';

class NewsletterController {
  constructor(form) {
    this.form = form
    this.htmlForm = this.form.querySelector('.js-newsletter__form')
    this.errors = []
    this.submitBtn = this.form.querySelector('.js-newsletter__submit')
    this.responseContainer = this.form.querySelector('.js-newsletter__response')
    this.fields = this.form.querySelectorAll('input[required]')
    this.email = this.form.querySelector('input.js-newsletter__email')
    this.terms = this.form.querySelector('.js-newsletter__terms')
    // this.defaultBtnText = this.submitBtn.dataset.textDefault
    // this.errorBtnText = this.submitBtn.dataset.error
    // this.successBtnText = this.submitBtn.dataset.success
    this.valid
  }

  init() {
    if (this.form == null) return
    this.proccess(this.form, this.fields)
  }

  proccess(form, fields) {
    let pristineConfig = {
      // class of the parent element where the error/success class is added
      classTo: 'js-pristine',
      errorClass: 'error',
      successClass: 'success',
      // class of the parent element where error text element is appended
      errorTextParent: 'js-pristine',
      // type of element to create for the error text
      errorTextTag: 'div',
      // class of the error text element
      errorTextClass: 'pristine-messsage',
    };


    let fieldsArr = Array.from(fields)
    let timeout = null;

    // create the pristine instance
    const pristine = new Pristine(form, pristineConfig);

    // listen for events
    // this.valid = this.validateResume()

    fieldsArr.forEach(field => {
      field.addEventListener('keyup', () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          // check if the form is valid
          this.valid = pristine.validate()
          if (this.valid == true) {
            this.submitBtn.disabled = false
          } else {
            this.submitBtn.disabled = true
          }
        }, 300);
      })
    });

    this.terms.addEventListener('change', () => {
      this.valid = pristine.validate()
      if (this.valid == true) {
        this.submitBtn.disabled = false
      } else {
        this.submitBtn.disabled = true
      }
    })

    // prepare form data and submit form.
    // this.submitBtn.addEventListener('click', (e) => {
    //   e.preventDefault();
    //   this.submit();
    // })
  }

  // eslint-disable-next-line no-unused-vars
  submit() {

    let data = new FormData();
    data.append('action', 'job_signup')
    data.append('email', this.email.value)

    // console.log(data);

    // eslint-disable-next-line no-undef
    axios.post('/', data)
      .then((response) => {
        console.log(response)
        if (response.data.data === 201) {
          // console.log('success', response)
          this.submitSuccess();
        } else {
          // console.log('error', response)
          this.submitError();
        }
        this.floatlabels.rebuild();
      })
      .catch((error) => {
        this.submitError();
        console.log(error.response.data);
      });
  }


  submitSuccess() {
    const _this = this
    _this.htmlForm.reset();
    _this.submitBtn.disabled = true;
    _this.responseContainer.textContent = _this.successBtnText
    setTimeout(() => {
      _this.responseContainer.textContent = ''
    }, 10000);
  }

  submitError() {
    const _this = this
    _this.responseContainer.textContent = _this.errorBtnText
    _this.htmlForm.reset();
    _this.submitBtn.disabled = true;
    setTimeout(() => {
      _this.responseContainer.textContent = ''
    }, 10000);
  }
}

export default NewsletterController
