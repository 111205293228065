class VariantQuantity {

  refreshButtonQuantity(quantityEl, quantity) {
    if (quantityEl == undefined) return;

    if (quantity == 0 || quantity == undefined) {
      quantityEl.innerHTML = '';
      quantityEl.classList.remove('e-btn__cartAmount');

    } else {
      quantityEl.innerHTML = `<span>${quantity}</span>`;
      quantityEl.classList.add('e-btn__cartAmount');
    }
  }

  setNotInStockClass(add) {
    // TODO: HOTFIX, find better mechanism for toggling this class
    const body = document.getElementsByTagName("body")[0];
    const className = "not-in-stock";
    
    if (add) {
      body.classList.add(className);
    } else {
      body.classList.remove(className);
    }
  }

  showAddToCartButton(productId) {
    this.setNotInStockClass(false);
    const addToCart = document.getElementById(`add_to_cart_${productId}`);

    if (addToCart == undefined) return;

    addToCart.style.display = 'flex';
    document.getElementById(`order_on_request_${productId}`).style.display = 'none';
  }

  showOnRequestButton(productId) {
    this.setNotInStockClass(true);
    const addToCart = document.getElementById(`add_to_cart_${productId}`);

    if (addToCart == undefined) return;

    addToCart.style.display = 'none';
    document.getElementById(`order_on_request_${productId}`).style.display = 'block';
  }
}

export let variantQuantity = new VariantQuantity();
