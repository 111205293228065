import Accordion from 'accordion-js';

class AccordionController {
  constructor() {
    this.DOM = {
      ac: '.js-ac',
    }
  }

  init() {

    this.set();

  }

  set() {
    if (document.querySelector(this.DOM.ac) == null) return
    let acc = Array.from(document.querySelectorAll(this.DOM.ac))
    new Accordion(acc, {
      duration: 300,
      showMultiple: false,
    });
  }

}

export let accordionController = new AccordionController();
