import flatpickr from 'flatpickr';

class DatepickerController {
  constructor() {
    this.DOM = {
      datepicker: '.js-datepicker',
    };
  }
  init() {
    if (this.DOM.datepicker !== null) {
      this.initDatepicker();
    } else {
      console.error(`${this.DOM.datepicker} does not exist in the DOM!`);
    }
  }

  initDatepicker() {
    const datepickers = document.querySelectorAll(this.DOM.datepicker);

    datepickers.forEach(datepicker => {
      let dataValMesg = datepicker.dataset.pristineRequiredMessage
      let fp = flatpickr(datepicker, {
        enableTime: false,
        disableMobile: true,
        enableSeconds: false,
        altInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'Y-m-d',
        minDate: '1910-01-01',
      })
      if (dataValMesg != undefined) {
        fp.altInput.dataset.pristineRequiredMessage = dataValMesg
      }
    });
  }
}

export let datepickerController = new DatepickerController();
