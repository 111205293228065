import { Controller } from 'stimulus';
import { googleTagManager } from "../packs/scripts/helpers/GoogleTagManager";

export default class extends Controller {
  static targets = ['infoButton'];

  trackAndRecaptchaHelpi = (token) => {
    token.preventDefault();

    if (window.grecaptcha !== undefined) {
      window.grecaptcha.execute();
    }

    googleTagManager.gaHelpi();
  }

  trackNewsLetter = () => {
    const email = document.getElementById('nwsemail');
    if (!email) return;

    googleTagManager.gaNewsletter(email.value);
  }

  trackProductSelect = (e) => {
    const data = e.target.dataset;

    googleTagManager.gaSelectProduct(data);
  }

  trackPromoBanner = (e) => {
    googleTagManager.gaPromoBanner('select_promotion', e.target.dataset);
  }

  trackSelectedCheckoutStep = (e) => {
    const targetData = this.hasInfoButtonTarget ? this.infoButtonTarget.dataset : e.target.dataset;
    const delivery = targetData.delivery === 'true';
    const payment = targetData.payment === 'true';

    if (!delivery && !payment) return;

    const gaCartOrder = document.getElementById('ga-cart-order');
    const gaLineItems = document.querySelectorAll(`[id^="ga-line-item"]`);
    const button = document.getElementById('navigationSubmitButton');

    if (delivery) {
      const selectedShipping = button.form.querySelector('.js-radioTabs__trigger:checked');
      if (!selectedShipping) return;

      const id = selectedShipping.id;
      const name = id === 'delivery' ? 'Dostava na adresu' : 'Preuzimanje u ljekarni';

      googleTagManager.gaShippingInformation(
        gaLineItems,
        gaCartOrder.dataset.currency,
        name,
        gaCartOrder.dataset.orderTotal,
      );

      googleTagManager.ga3ShippingInformation(gaLineItems, gaCartOrder.dataset.currency, name);
    } else if (payment) {
      const selectedPayment = button.form.querySelector('.js-radioTabs__trigger:checked');
      if (!selectedPayment) return;

      const id = selectedPayment.id;
      const name = id === 'online' ? 'Online' : 'Pri preuzimanju';

      googleTagManager.gaPaymentInformation(
        gaLineItems,
        gaCartOrder.dataset.currency,
        name,
        gaCartOrder.dataset.orderTotal,
      );

      googleTagManager.ga3PaymentInformation(gaLineItems, gaCartOrder.dataset.currency, name);
    }
  }

  trackViewItemList = () => {

  }
 }
