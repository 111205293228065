import { Controller } from 'stimulus';
import MicroModal from 'micromodal';
// import { orderLocationController } from '../packs/scripts/OrderLocationController';
import OrderLocationControllerV2 from '../packs/scripts/OrderLocationControllerV2';
import { selectAddressController } from "../packs/scripts/SelectAddressController";
import { spreeApiController } from "../packs/scripts/SpreeApiController";
import { ErrorCodes } from '../packs/scripts/errorCodes';
import { variantQuantity }  from '../packs/scripts/helpers/VariantQuantity';
import { cart }  from '../packs/scripts/helpers/Cart';
import { notyController } from "../packs/scripts/NotyController";
import { googleTagManager } from "../packs/scripts/helpers/GoogleTagManager";

export default class extends Controller {
  static targets = ['showLocation', 'quantity', 'productAmountToAdd']

  initialize() {
    this.productId = this.showLocationTarget.dataset.productId;

    const quantity = parseInt(this.quantityTarget.dataset.itemCount);
    variantQuantity.refreshButtonQuantity(this.quantityTarget, quantity);
  }

  async show(e) {
    e.preventDefault();

    await this.ensureOrderToken();
    await this.addItem();
  }

  async ensureOrderToken() {
    if (spreeApiController.orderToken.length === 0) {
      const source = await fetch(`/ensure_cart`, {method: 'POST'});
      const data = await source.json();

      spreeApiController.orderToken = data.token
    }
  }

  async addItem() {
    this.showLocationTarget.disabled = true;
    const variant = this.showLocationTarget.dataset
    const variantId = variant.variantId;
    const quantity =
      this.hasProductAmountToAddTarget ? parseInt(this.productAmountToAddTarget.value) : 1;

    await this.ensureOrderToken();

    const headers = spreeApiController.prepareHeaders();
    const url = '/api/v2/storefront/cart/add_item';
    const source = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        variant_id: variantId,
        quantity: quantity,
        options: { ...{ item_list_name: variant.variantPageTitle }, ...this.getPromo(variant) },
      }),
    });

    await this.handleAddItemResponse(source, variantId, quantity, this.showLocationTarget.dataset);
    this.showLocationTarget.disabled = false;

    return source;
  }

  async handleAddItemResponse(source, variantId, quantity, showDataset) {
    switch (source.status) {
      case 422:
        const error = await source.json();
        if (error.errors.code === ErrorCodes.invalidStockLocation) {
          this.openMicroModal(variantId);
        }
        if (error.errors.quantity) {
          variantQuantity.showOnRequestButton(this.productId);
        }
        if (error.errors.item_count) {
          notyController.show('error', error.errors.item_count);
        }
        break;
      case 404:
        break;
      case 500:
        break;
      case 200:
        const data = await source.json();
        this.updateQuantity(data.meta, variantId);
        cart.refreshTotal(data);

        if (this.hasQuantityTarget && this.quantityTarget.dataset.notification) {
          notyController.show('success', this.quantityTarget.dataset.notification);
        }

        googleTagManager.gaAddToCart(showDataset, quantity);

        break;
    }
  }

  updateQuantity = (meta, variantId) => {
    const variantQuantities = meta.variant_line_items;
    this.quantityTarget.dataset.itemCounts = JSON.stringify(variantQuantities);

    variantQuantity.refreshButtonQuantity(this.quantityTarget, variantQuantities[variantId]);
  }

  openMicroModal(variantId) {
    MicroModal.show('order-location-choice-v2', {
      onShow: modal => {
        if (modal.id === 'order-location-choice-v2') {
          if (!this.orderLocationControllerV2) {
            this.orderLocationControllerV2 = new OrderLocationControllerV2(
              modal
            );
          }
          this.orderLocationControllerV2.init('#autoComplete');
          selectAddressController.init();
          document.getElementById('output').dataset.variantId = variantId;

        }
      },
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      debugMode: true
    });
  }

  getPromo = (variant) => {
    if (!variant.variantPromotionName) return {};

    return { promotion_name: variant.variantPromotionName };
  }
}
