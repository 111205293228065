import { Controller } from 'stimulus';
import { googleTagManager } from "../packs/scripts/helpers/GoogleTagManager";

export default class extends Controller {
  initialize() {
    this.gaCartOrder = document.getElementById('ga-cart-order');
    this.gaLineItems = document.querySelectorAll(`[id^="ga-line-item"]`)
    if (this.gaLineItems === undefined || this.gaLineItems.length === 0) return;

    googleTagManager.gaViewCart(
      this.gaLineItems,
      this.gaCartOrder.dataset.currency,
      this.gaCartOrder.dataset.orderTotal,
    );
  }

  initiateCheckout = () => {
    this.gaCartOrder = document.getElementById('ga-cart-order');
    this.gaLineItems = document.querySelectorAll(`[id^="ga-line-item"]`)
    if (this.gaLineItems === undefined) return;

    googleTagManager.gaInitiateCheckout(
      this.gaLineItems, this.gaCartOrder.dataset.currency, this.gaCartOrder.dataset.orderTotal,    );
    googleTagManager.ga3InitiateCheckout(this.gaLineItems);
  }
}
