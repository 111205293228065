import { Controller } from 'stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  static targets = ['tickerData'];

  closeTicker = (e) => {
    const closedTickers = Cookies.get('closed_tickers') || '';
    const tickerId = String(this.tickerDataTarget.dataset.tickerId);
    const closedTickerIds = closedTickers === '' ? tickerId : closedTickers + `,${tickerId}`;

    Cookies.set('closed_tickers', closedTickerIds);

    const ticker = document.getElementById(`ticker-${tickerId}`);

    this.removeTickerAfterFade(ticker)
    ticker.classList.add('c-ticker--fade-out');
  }

  removeTickerAfterFade = (ticker) => {
    ticker.onanimationend = (e) => {
      if (e.target.classList.contains('c-ticker--fade-out')) {
        ticker.style.display = 'none';
      }
    };
  }
}
