import wNumb from 'wnumb';
import noUiSlider from 'nouislider';


class RangeSliderController {
  constructor() {
    this.slider

  }
  init() {
    this.slider = document.getElementById('slider-range')

    if (this.slider != null) {
      this.events();
    }
  }

  events() {

    let rangeSlider = document.getElementById('slider-range');
    let sliderRangeValue1 = document.getElementById('slider-range-value1');
    let sliderRangeValue2 = document.getElementById('slider-range-value2');
    const moneyFormat = wNumb({
      decimals: 0,
      thousand: rangeSlider.getAttribute('data-thousand'),
      suffix: ' ' + rangeSlider.getAttribute('data-currency')
    });

    const minValue = Number(sliderRangeValue1.attributes['initial-value'].value);
    const maxValue = Number(sliderRangeValue2.attributes['initial-value'].value);
    const step = (maxValue - minValue) / 100;

    const startingValue1 = Number(document.getElementsByName('min-value')[0].value)
    const startingValue2 = Number(document.getElementsByName('max-value')[0].value)

    noUiSlider.create(rangeSlider, {
      start: [startingValue1, startingValue2],
      step: step,
      range: {
        'min': [minValue],
        'max': [maxValue]
      },
      format: moneyFormat,
      connect: true
    });

    // Set visual min and max values and also update value hidden form inputs
    rangeSlider.noUiSlider.on('update', function (values, handle) {
      sliderRangeValue1.innerHTML = values[0];
      sliderRangeValue2.innerHTML = values[1];
      document.getElementsByName('min-value').forEach(element => {
        const oldValue = Number(element.value);
        const newValue = moneyFormat.from(values[0]);
        element.value = newValue;

        if (oldValue !== newValue) {
          element.dispatchEvent(new Event('change'));
        }
      });
      document.getElementsByName('max-value').forEach(element => {
        const oldValue = Number(element.value);
        const newValue = moneyFormat.from(values[1]);
        element.value = newValue;

        if (oldValue !== newValue) {
          element.dispatchEvent(new Event('change'));
        }
      });
    });
  }

}



export let rangeSliderController = new RangeSliderController();
