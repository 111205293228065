import List from 'list.js';

class TableSearchController {
  constructor(id) {
    this.DOM = {
      table: id,
    }
  }

  init() {
    this.event();

  }

  event() {
    const options = {
      valueNames: ['js-order-number']
    };

    const ordersList = new List(this.DOM.table, options);
  }

}

export default TableSearchController
