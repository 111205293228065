import { visibility } from "./Visibility";
import { namingConverter } from "./NamingConverter" ;

export const addressAttributes = ["firstname", "lastname", "phone", "zipcode", "city", "street_name", "house_number"]

class AddressList {
  updateHiddenEditForm(addressController, address, index) {
    addressAttributes.forEach(function (attr) {
      let camel_case_attr = namingConverter.toCamelCase(attr);
      addressController[`${camel_case_attr}Targets`][index].innerText = address[camel_case_attr];
    });
  }

  updateAddressList(addressController, address, index) {
    const fullInfo = this.addressFullInfo(address);
    if (fullInfo === undefined) return;

    addressController.addressFullInfoTargets[index].innerText = fullInfo;
  }

  addressFullInfo(address) {
    if (address === undefined) return;

    return address.firstname + ' '
      + address.lastname + ', '
      + address.streetName + ' '
      + address.houseNumber + ', '
      + address.zipcode + ' '
      + address.city;
  }

  removeAddress(addressController, addressId) {
    const htmlElementId = 'delivery_address_' + addressId + '_row';
    const htmlElement = document.getElementById(htmlElementId);
    if (htmlElement === undefined) return;

    htmlElement.remove();

    if (addressController.addressEditTargets.length === 1) {
      visibility.showTargets([addressController.addnewsectionTarget], 'block');
    }
  }

  errorList(error, elementTarget) {
    if (error === undefined) return;

    let form = elementTarget.closest('form');
    let errorContainer = form.querySelector('.js-error-messages');
    if (errorContainer === undefined) return;

    errorContainer.style.display = 'block';
    errorContainer.innerHTML = `<div class="e-alert e-alert--dangerOutline mt-xs">
              <span class="e-alert__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <path d="M21.9 8.3L19.1 5.6 13.6 11.1 8.2 5.7 5.7 8.2 11.1 13.6 5.6 19.1 8.3 21.9 13.9 16.4 19.3 21.8 21.8 19.3 16.4 13.9z"/></svg>
              </span>
              <span class="e-alert__content">
                <div class="js-error-messages__output">
                  ${error.errors.base}
                </div>
              </span>
            </div>`
  }

  displayButtons(addressController) {
    visibility.showTargets(addressController.addnewsectionbuttonTargets, 'block');
    visibility.showTargets(addressController.addressEditTargets, 'inline-flex');
  }
}

export let addressList = new AddressList();
