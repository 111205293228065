const loadGoogleMapsApi = require('load-google-maps-api');
import { stylers } from './stylers'
import markerTmpl from './marker.tmpl'

let globalMarkerStore = { markers: [] }
let selectedGroups = []

/**
 * Location Map
 * Main map rendering function
 * @param {string} el - Google Map selector
 */
export function GMap(el, apiKey) {

  const gApiKey = apiKey
  const mapEl = document.querySelector(el)
  const zoom = parseFloat(mapEl.dataset.zoom ? mapEl.dataset.zoom : 12)
  const mapMarkersData = mapEl.querySelectorAll('.js-marker')
  const data = []

  // Call map renderer
  loadGoogleMapsApi({ key: gApiKey }).then(function () {

    let userIcon = {
      url: mapEl.dataset.userIcon ? mapEl.dataset.userIcon : '',
      scaledSize: new google.maps.Size(38, 50)
    }

    mapMarkersData.forEach(marker => {
      data.push({
        lat: parseFloat(marker.dataset.lat ? marker.dataset.lat : 0),
        lng: parseFloat(marker.dataset.lng ? marker.dataset.lng : 0),
        address: marker.dataset.address ? marker.dataset.address : "",
        groupId: marker.dataset.id,
        title: marker.dataset.title ? marker.dataset.title : "Map",
        defaultIcon: {
          url: marker.dataset.default ? marker.dataset.default : stylers.icons.pharmacy,
          scaledSize: new google.maps.Size(38, 50)
        },
        activeIcon: {
          url: marker.dataset.active ? marker.dataset.active : stylers.icons.pharmacy_active,
          scaledSize: new google.maps.Size(38, 50)
        }
      })
    });

    renderMap(mapEl, zoom, data, userIcon)

    let resetBtn = document.querySelector('button#resetGroups');
    resetBtn.addEventListener('click', (e) => {
      document.querySelectorAll('input[name="locatorfiltertype"]').forEach(button => {
        button.checked = false;
      })
      selectedGroups = [];
      globalMarkerStore.markers.forEach((marker) => {
        marker.setVisible(true);
      })
    });

    document.querySelectorAll('input[name="locatorfiltertype"]').forEach(button => {
      button.addEventListener('click', (e) => {
        if (button.checked) {
          selectedGroups.push(button.value);
        }
        else {
          if(selectedGroups.indexOf(button.value) > -1) {
            selectedGroups.splice(selectedGroups.indexOf(button.value), 1);
          }
        }

        let showMarkers = [];

        globalMarkerStore.markers.forEach((marker) => {
          marker.setVisible(false)
        })

        selectedGroups.forEach((group) => {
          globalMarkerStore.markers.filter(function(item){
            if (item.groupId == group) {
              showMarkers.push(item)
            };
            return false
          });
        });

        showMarkers.forEach((marker) => {
          marker.setVisible(true)
        });
      });
    });
  })
}

/**
 * Render Map
 * @param {map obj} mapEl - Google Map
 * @param {obj} data - map data
 */
export function renderMap(mapEl, zoom, data, userIcon) {

  const options = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: stylers.styles,
    zoom: zoom,
    center: {
      lat: data[0].lat,
      lng: data[0].lng
    },
  }
  const map = new google.maps.Map(mapEl, options)
  userGeolocation(map, userIcon)

  const infowindow = new google.maps.InfoWindow()
  let gmMarkers = []

  for (let i = 0; i < data.length; i++) {
    renderMarker(map, data[i], userIcon, infowindow, gmMarkers)
  }
  mapPan(map, data)
  globalMarkerStore.map = map;
  globalMarkerStore.icons = stylers.icons;
  globalMarkerStore.infowindow = infowindow;
  globalMarkerStore.gmMarkers = gmMarkers;
}

export function mapPan(map, marker) {
  map.panTo({
    lat: marker[0].lat,
    lng: marker[0].lng
  });
}

/**
 * Render Marker
 * Renders custom map marker and infowindow
 * @param {map element} mapEl
 * @param {object} data
 * @param {icons obj} icons
 * @param {infowindow} infoWindow
 * @param {gmMarkers array} gmMarkers
 */
export function renderMarker(map, data, userIcon, infowindow, gmMarkers) {

  const tmpl = markerTmpl(data)

  const marker = new google.maps.Marker({
    position: new google.maps.LatLng(data.lat, data.lng),
    map: map,
    icon: data.defaultIcon,
    title: data.title,
    content: tmpl,
    groupId: data.groupId,
    animation: google.maps.Animation.DROP
  })

  gmMarkers.push(marker)
  globalMarkerStore.markers.push(marker)

  handleMarkerClick(map, marker, infowindow, userIcon, gmMarkers, data)
}

/**
 * Handle Marker Click
 *
 * @param {map obj} mapEl
 * @param {marker} marker
 * @param {infowindow} infoWindow
 * @param {icons obj} icons
 * @param {gmMarkers array} gmMarkers
 */
export function handleMarkerClick(map, marker, infowindow, userIcon, gmMarkers, data) {

  google.maps.event.addListener(marker, 'click', function () {
    for (let i = 0; i < gmMarkers.length; i++) {
      gmMarkers[i].setIcon(data.defaultIcon);
    }
    infowindow.setContent(marker.content)
    infowindow.open(map, marker)
    map.panTo(marker.getPosition());
    this.setIcon(data.activeIcon);
  })

  google.maps.event.addListener(map, 'click', function (event) {
    marker.setIcon(data.defaultIcon);
    if (infowindow) {
      infowindow.close(map, infowindow)
    }
  })
}

/**
 * Handle User Geolocation
 *
 */
export function userGeolocation(map, userIcon) {
  // Get proper error message based on the code.
  const getPositionErrorMessage = code => {
    switch (code) {
      case 1:
        return 'Permission denied.';
      case 2:
        return 'Position unavailable.';
      case 3:
        return 'Timeout reached.';
    }
  }
  // Get user's location
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      position => {
        // console.log(`Lat: ${position.coords.latitude} Lng: ${position.coords.longitude}`);
        let marker = new google.maps.Marker({
          position: new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
          map: map,
          icon: stylers.icons.user,
          animation: google.maps.Animation.DROP
        })
        // Center map to user's position.
        map.panTo({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      },
      err => alert(`Error (${err.code}): ${getPositionErrorMessage(err.code)}`)
    );
  } else {
    alert('Geolocation is not supported by your browser.');
  }
}
