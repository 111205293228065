// import Choices from 'choices.js'

export default class AddToListController {
  constructor(modal) {
    this.modal = modal
    this.DOM = {
      select: this.modal.querySelector('.js-addToListModal-select'),
      newField: this.modal.querySelector('.js-addToListModal-new'),
      newInput: this.modal.querySelector('.js-addToListModal-new input'),
      submit: this.modal.querySelector('.js-addToListModal-submit'),
    }
  }
  init() {
    this.DOM.select.addEventListener('change', (e)=>{
      let value = e.target.options[e.target.selectedIndex].value
      if (value == 'new') {
        this.DOM.newField.style.display = 'block'
      } else {
        this.DOM.newField.style.display = 'none'
      }
    })
  }
}
