import { Controller } from 'stimulus';
import { googleTagManager } from "../packs/scripts/helpers/GoogleTagManager";

export default class extends Controller {
  initialize() {
    this.gaCartOrder = document.getElementById('ga-cart-order');
    this.gaLineItems = document.querySelectorAll(`[id^="ga-line-item"]`)
    if (this.gaLineItems === undefined) return;

    googleTagManager.gaPurchase(this.gaLineItems, this.gaCartOrder.dataset);
    googleTagManager.gadsConversionPurchase(this.gaCartOrder.dataset);
  }
}
