import { Controller } from "stimulus";
import { spreeApiController } from "../packs/scripts/SpreeApiController";
import { notyController } from "../packs/scripts/NotyController";
import { googleTagManager } from "../packs/scripts/helpers/GoogleTagManager";

export default class extends Controller {
  static targets = [
    "product",
    "signInFavoritesModal",
    "signInFavoritesModalBackground",
    "buttonWrapper",
  ];

  initialize() {
    this.productId = this.productTarget.dataset.productId;
  }

  async toggleFavorite(e) {
    e.preventDefault();

    await spreeApiController.ensureTokens();
    const headers = spreeApiController.prepareHeaders();
    const url = "/api/v2/storefront/account/favorites/toggle";

    const source = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        product_id: this.productId,
      }),
    });

    await this.handleResponse(source);
  }

  async handleResponse(source) {
    if (source.status === 200) {
      const data = await source.json();
      this.notifyUser(data);
    } else if (source.status === 403) {
      notyController.show(
        "info",
        "Prijavite se da bi mogli dodati proizvode u omiljene proizvode"
      );
    }
  }

  notifyUser(data) {
    const isFavorite = data.data.attributes.is_favorite;
    let message;

    if (isFavorite) {
      message = "Dodali ste proizvod u omiljene proizvode.";

      if (this.productTarget.dataset.redesign) {
        const buttons = document.querySelectorAll(
          "button.js-productAbility--favorite"
        );

        buttons.forEach((b) => {
          if (b.className.includes("redesignResponsive")) {
            b.classList.add("is--active-2");
          } else {
            b.classList.add("is--active");
          }
        });
      } else {
        this.productTarget.classList.add("is--active");
      }

      googleTagManager.gaAddToWishlist(this.productTarget.dataset);
    } else {
      message = "Uklonili ste proizvod iz omiljenih proizvoda.";

      if (this.productTarget.dataset.redesign) {
        const buttons = document.querySelectorAll(
          "button.js-productAbility--favorite"
        );

        buttons.forEach((b) => {
          if (b.className.includes("redesignResponsive")) {
            b.classList.remove("is--active-2");
          } else {
            b.classList.remove("is--active");
          }
        });
      } else {
        this.productTarget.classList.remove("is--active");
      }
    }

    notyController.show("success", message);
  }

  signInModal = () => {
    this.signInFavoritesModalTarget.ariaHidden = false;
    this.signInFavoritesModalBackgroundTarget.style.display = "flex";
    this.signInFavoritesModalTarget.style.visibility = "visible";
    this.buttonWrapperTarget.style.visibility = "visible";
  };

  closeSignInModal = () => {
    this.signInFavoritesModalTarget.ariaHidden = true;
    this.signInFavoritesModalTarget.style.visibility = "hidden";
    this.buttonWrapperTarget.style.visibility = "hidden";
    this.signInFavoritesModalBackgroundTarget.style.display = "none";
  };
}
