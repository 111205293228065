import { googleTagManager } from "../../packs/scripts/helpers/GoogleTagManager";
class OrderController {
  constructor() {
    this.DOM = {
      deleteButtons: '.js-cart_item_delete_button',
      cartUpdateButtons: '.js-cart_update_button',
      quantityInputs: '.js-shopping-cart-item-quantity-input',
    }
  }

  init() {
    if (this.DOM.deleteButtons !== null && this.DOM.cartUpdateButtons !== null) {
      this.inputEvents();
    } else {
      console.error(
        `${this.DOM.deleteButtons} or ${this.DOM.cartUpdateButtons} do not exist in the DOM!`
      );
    }
  }

  inputEvents() {
    let deleteButtons = document.querySelectorAll(this.DOM.deleteButtons);
    let cartUpdateButtons = document.querySelectorAll(this.DOM.cartUpdateButtons);
    let quantityInputs = document.querySelectorAll(this.DOM.quantityInputs);
    let updateCart = document.getElementById('update-cart');
    let timeout;

    quantityInputs.forEach((input) => {
      input.addEventListener('keyup', (e) => {
        if (e.keyCode === 13 && quantityInputs.length > 1) { updateCart.submit() };
      });

      input.addEventListener('change', () => {
        if (timeout) window.clearTimeout(timeout);

        timeout = setTimeout(() => { updateCart.submit() }, 600);
      })
    });

    deleteButtons.forEach((button) => {
      button.addEventListener('click', () => {
        let itemId = button.getAttribute('data-id');

        const item = document.querySelector(
          "form#update-cart input.js-shopping-cart-item-quantity-input[data-id='" + itemId + "']",
        )
        item.setAttribute('value', 0);

        const itemDataset = document.querySelector(
          `[id^="ga-line-item"][data-id=${itemId}]`
        ).dataset;

        googleTagManager.gaRemoveFromCart(itemDataset, Math.abs(item.dataset.newQuantity));

        updateCart.submit();
      });
    });

    cartUpdateButtons.forEach((button) => {
      button.addEventListener('click', () => {
        const quantityDown = button.classList.contains('js-quantity__down') ? true : false;
        const quantities = document.querySelectorAll(
          `form#update-cart input.js-shopping-cart-item-quantity-input`
        );
        const quantity = Array.from(quantities).find(q => q.dataset.id === button.dataset.id);

        if (quantityDown) {
          quantity.dataset.newQuantity = String(Number(quantity.dataset.newQuantity) - 1);
        } else {
          quantity.dataset.newQuantity = String(Number(quantity.dataset.newQuantity) + 1);
        }

        if (timeout) window.clearTimeout(timeout);

        timeout = setTimeout(() => {
          quantities.forEach(q => {
            const itemQuantity = Number(q.dataset.newQuantity) - Number(q.dataset.originalQuantity);

          if (itemQuantity > 0) {
            const itemDataset = document.querySelector(
              `[id^="ga-line-item"][data-id=${q.dataset.id}]`
            ).dataset;

            googleTagManager.gaAddToCart(itemDataset, itemQuantity);
          } else if (itemQuantity < 0) {
            const itemDataset = document.querySelector(
              `[id^="ga-line-item"][data-id=${q.dataset.id}]`
            ).dataset;

            googleTagManager.gaRemoveFromCart(itemDataset, Math.abs(itemQuantity));
          }
          })

          updateCart.submit()
        }, 600);
      });
    });
  }
}

export let orderController = new OrderController();
