import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "benefits",
    "benefitsButton",
    "benefitsButtonReverse",
    "benefitsTable",
    "propertiesList",
    "propertiesButton",
    "propertiesButtonReverse",
    "faqList",
    "faqButton",
    "faqButtonReverse",
  ];

  displayBenefits = () => {
    this.benefitsTableHeight = this.benefitsTableTarget.style.height;
    this.benefitsTableTarget.style.height = "100%";

    this.benefitsButtonTarget.style.display = "none";
    this.benefitsButtonReverseTarget.style.display = "flex";
  };

  hideBenefits = () => {
    this.benefitsButtonTarget.style.display = "flex";
    this.benefitsButtonReverseTarget.style.display = "none";

    this.benefitsTableTarget.style.height = this.benefitsTableHeight;
  };

  displayProperties = () => {
    this.gridRows = this.propertiesListTarget.style.gridTemplateRows;
    this.propertiesListTarget.style.gridTemplateRows = "1fr";

    this.propertiesButtonTarget.style.display = "none";
    this.propertiesButtonReverseTarget.style.display = "flex";
  };

  hideProperties = () => {
    this.propertiesButtonTarget.style.display = "flex";
    this.propertiesButtonReverseTarget.style.display = "none";

    this.propertiesListTarget.style.gridTemplateRows = this.gridRows;
  };

  displayFaq = () => {
    this.faqGrid = this.faqListTarget.style.gridTemplateRows;
    this.faqListTarget.classList.remove("faqWrapperHidden--noBorder");
    this.faqListTarget.style.gridTemplateRows = "1fr";

    this.faqButtonTarget.style.display = "none";
    this.faqButtonReverseTarget.style.display = "flex";
  };

  hideFaq = () => {
    this.faqButtonTarget.style.display = "flex";
    this.faqButtonReverseTarget.style.display = "none";

    this.faqListTarget.style.gridTemplateRows = this.faqGrid;
    this.faqListTarget.style.border = "0";
    this.faqListTarget.classList.add("faqWrapperHidden--noBorder");
  };

  expandSingleFaq = (e) => {
    const parent = e.target.closest(".faq");
    const target = parent.querySelector(".faqTextWrapper");

    if (target.className.includes("faqTextWrapper--hidden")) {
      target.classList.remove("faqTextWrapper--hidden");
      parent.querySelector(".faqToggleW").classList.add("faqToggleW--rotate");
    } else {
      target.classList.add("faqTextWrapper--hidden");
      parent
        .querySelector(".faqToggleW")
        .classList.remove("faqToggleW--rotate");
    }
  };
}
