import { Controller } from 'stimulus';
import MicroModal from 'micromodal';
import AddToListController from "../packs/scripts/AddToListController";

export default class extends Controller {
  static targets = ['product']

  initialize() {
    this.variantIds = JSON.parse(this.productTarget.dataset.variantIds);
  }

   showBuyingListModal() {
    this.openMicroModal();
    this.dispatchEvent();
  }

  openMicroModal() {
    MicroModal.show('add-to-list', {
      onShow: modal => {
        if (modal.id === 'add-to-list') {
          let addToListController = new AddToListController(modal);
          addToListController.init();
        }
      },
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      debugMode: true
    });
  }

  dispatchEvent() {
    if(this.variantIds.length === 1 ){
      const event = new CustomEvent("sendVariantId", { detail: { variant_id: this.variantIds[0] } })
      window.dispatchEvent(event)
    }
  }
}
