class GoogleTagManager {
  gaAddToCart(dataset, quantity) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });

    this.tagCart('add_to_cart', dataset, quantity);
  }

  gaRemoveFromCart(dataset, quantity) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });

    this.tagCart('remove_from_cart', dataset, quantity);
  }

  gaSignin(type) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: type,
      method: 'webshop'
    });
  }

  gaViewItemList(items, currency, orderTotal) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        currency: currency,
        items: this.calculateGAListItems(items),
      }
    });
  }

  ga3ViewItemList(items, currency) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'eec.impressionView',
      ecommerce: {
        impressions: this.calculateGA3ListItems(items, currency),
      }
    });
  }

  gaSelectProduct(dataset) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });

    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        currency: dataset.currency,
        items: [{ ...{
          item_name: dataset.variantName,
          item_id: dataset.variantSku,
          price: Number(dataset.variantPrice),
          affiliation: dataset.variantAffiliation,
          item_brand: dataset.variantBrand,
          item_variant: dataset.variantName,
          item_list_name: dataset.variantPageTitle,
          index: Number(dataset.variantListPosition),
          quantity: 1,
          pp_badge: dataset.ppBadge,
          discount_badge: dataset.saleBadge,
        }, ...this.getDiscount(dataset),
           ...this.getCategories(dataset),
           ...this.getPromo(dataset) }]
      }
    });
  }

  gaViewProduct(dataset) {
    this.viewItemTag(dataset, 1);
  }

  gaNewsletter(email) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'newsletter_signup',
      email: email
    })
  }

  gaHelpi() {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      'event': 'lead'
    })
  }

  gaPromoBanner(event, dataset) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: event,
      ecommerce: {
        creative_name: dataset.creativeName,
        creative_slot: dataset.creativeSlot,
        promotion_id: dataset.promotionId,
        promotion_name: dataset.promotionName,
      }
    });
  }

  gaAddToWishlist(dataset) {
    this.addTag('add_to_wishlist', dataset, 1)
  }

  gaAddToBuyinglist(dataset, quantity, orderTotal = null) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'buying_list',
      value: orderTotal && Number(orderTotal) || Number(dataset.variantPrice) * Number(quantity),
      currency: dataset.currency,
    });
  }

  gaViewCart(lineItems, currency, orderTotal) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        currency: currency || 'HRK',
        value: Number(orderTotal),
        items: this.calculateGAItems(lineItems)
      },
    });
  }

  gaInitiateCheckout(lineItems, currency, orderTotal) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        currency: currency || 'HRK',
        value: Number(orderTotal),
        items: this.calculateGAItems(lineItems),
      },
    });
  }

  ga3InitiateCheckout(lineItems) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'eec.checkout',
      ecommerce: {
        checkout: {
          actionField: { step: 1 },
          products: this.calculateGA3Items(lineItems)
        }
      },
    });
  }

  gaShippingInformation(lineItems, currency, shippingName, orderTotal) {
    window.dataLayer = window.dataLayer || [];

    try {
      window.dataLayer.push({
        event: 'add_shipping_info',
        ecommerce: {
          currency: currency || 'HRK',
          shipping_tier: shippingName,
          value: Number(orderTotal),
          items: this.calculateGAItems(lineItems),
        },
      });
    } catch (e) {
      console.log('Something is wrong - ' + e)
    }
  }

  ga3ShippingInformation(lineItems, currency, shippingName) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'eec.checkout',
      ecommerce: {
        checkout: {
          actionField: { step: 2, option: shippingName },
          products: this.calculateGA3Items(lineItems, currency),
        }
      },
    });
  }

  gaPaymentInformation(lineItems, currency, paymentName, orderTotal) {
    window.dataLayer = window.dataLayer || [];

    try {
      window.dataLayer.push({
        event: 'add_payment_info',
        ecommerce: {
          currency: currency || 'HRK',
          payment_type: paymentName,
          value: Number(orderTotal),
          items: this.calculateGAItems(lineItems)
        },
      });
    } catch (e) {
      console.log('Something is wrong - ' + e)
    }
  }

  ga3PaymentInformation(lineItems, currency, paymentName) {
    window.dataLayer = window.dataLayer || [];

    try {
      window.dataLayer.push({
        event: 'eec.checkout',
        ecommerce: {
          checkout: {
            actionField: { step: 3, option: paymentName },
            products: this.calculateGA3Items(lineItems, currency),
          }
        },
      });
    } catch (e) {
      console.log('Something is wrong - ' + e)
    }
  }


  calculateGAItems(selectedItems) {
    let items = [];

    for (const item of selectedItems) {
      items.push(
        {...{
          item_name: item.dataset.variantName,
          item_id: item.dataset.variantSku,
          price: Number(item.dataset.variantPrice),
          affiliation: item.dataset.variantAffiliation,
          item_brand: item.dataset.variantBrand,
          item_variant: item.dataset.variantName,
          quantity: Number(item.dataset.variantQuantity),
          item_list_name: item.dataset.variantPageTitle,
          pp_badge: item.dataset.ppBadge,
          discount_badge: item.dataset.saleBadge,
        },
          ...this.getDiscount(item.dataset),
          ...this.getCategories(item.dataset),
          ...this.getPromo(item.dataset),
        }
      )
    }

    return items;
  }

  calculateGAListItems(selectedItems) {
    let items = [];

    const currentListTitle = document.getElementById('current_list_title');

    for (const item of selectedItems) {
      items.push(
        {...{
          item_name: item.dataset.variantName,
          item_id: item.dataset.variantSku,
          price: Number(item.dataset.variantPrice),
          affiliation: item.dataset.variantAffiliation,
          item_brand: item.dataset.variantBrand,
          item_list_name: item.dataset.variantPageTitle,
          item_variant: item.dataset.variantName,
          quantity: Number(item.dataset.variantQuantity),
          index: Number(item.dataset.variantListPosition),
          pp_badge: item.dataset.ppBadge,
          discount_badge: item.dataset.saleBadge,
        }, ...this.getDiscount(item.dataset),
           ...this.getCategories(item.dataset),
           ...this.getPromo(item.dataset),
        }
      )
    }

    return items;
  }


  calculateGA3Items(selectedItems, currency) {
    let items = [];

    for (const item of selectedItems) {
      items.push(
        {
          name: item.dataset.variantName,
          id: item.dataset.variantSku,
          price: Number(item.dataset.variantPrice),
          affiliation: item.dataset.variantAffiliation,
          brand: item.dataset.variantBrand,
          category: '', // TODO: what is item category?
          variant: item.dataset.variantName,
          quantity: Number(item.dataset.variantQuantity),
          currencyCode: currency,
        }
      )
    }

    return items;
  }

  calculateGA3ListItems(selectedItems, currency) {
    let items = [];

    for (const item of selectedItems) {
      items.push(
        {
          name: item.dataset.variantName,
          id: item.dataset.variantSku,
          price: Number(item.dataset.variantPrice),
          affiliation: item.dataset.variantAffiliation,
          brand: item.dataset.variantBrand,
          category: '', // TODO: what is item category?
          variant: item.dataset.variantName,
          quantity: Number(item.dataset.variantQuantity),
          currencyCode: currency,
          list: item.dataset.variantPageTitle,
          position: item.dataset.variantListPosition,
        }
      )
    }

    return items;
  }

  gaPurchase(lineItems, globalDataset) {
    window.dataLayer = window.dataLayer || [];

    const purchaseMetadata = {
      shipping_tier: globalDataset.shippingTier,
      payment_type: globalDataset.paymentType
    };

    try {
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: { ...{
          currency: globalDataset.currency || 'HRK',
          value: Number(globalDataset.orderTotal),
          tax: Number(''), // TODO: we don't have tax before send order to ERP
          shipping: Number(globalDataset.shipTotal),
          transaction_id: globalDataset.orderNumber,
          email: globalDataset.email,
          coupon: '', // TODO: if we have free shipping and coupon?
          items: this.calculateGAItems(lineItems)
        }, ...purchaseMetadata },
      });
    } catch (e) {
      console.log('Something is wrong - ' + e)
    }
  }

  gadsConversionPurchase(globalDataset) {
    window.dataLayer = window.dataLayer || [];

    const addressData = {
      firstName: globalDataset.addressFirstname,
      lastName: globalDataset.addressLastname,
      street: globalDataset.addressStreet,
      city: globalDataset.addressCity,
      region: globalDataset.addressRegion,
      country: globalDataset.addressCountry,
      postalCode: globalDataset.addressPostalCode
    }

    try {
      window.dataLayer.push({
        event: 'gads_conversion_purchase',
        transaction_id: globalDataset.orderNumber,
        value: Number(globalDataset.orderTotal),
        shipping: Number(globalDataset.shipTotal),
        tax: Number(''),
        currency: globalDataset.currency || 'EUR',
        email: globalDataset.email,
        phoneNumber: globalDataset.phoneNumber, // rijesiti
        userAddress: addressData,
        customerType: globalDataset.customerType, // new || returning
        customerLtv: globalDataset.customerLtv
      });
    } catch (e) {
      console.log('Something is wrong gads - ' + e)
    }
  }

  tagCart(event, dataset, quantity) {
    window.dataLayer = window.dataLayer || [];

    try {
      window.dataLayer.push({
        event: event,
        ecommerce: {
          currency: dataset.currency,
          value: Number(dataset.variantPrice) * Number(quantity),
          items: [{...{
            item_name: dataset.variantName,
            item_id: dataset.variantSku,
            price: Number(dataset.variantPrice),
            affiliation: dataset.variantAffiliation,
            item_brand: dataset.variantBrand,
            item_variant: dataset.variantName,
            item_list_name: dataset.variantPageTitle,
            quantity: Number(quantity),
            pp_badge: dataset.ppBadge,
            discount_badge: dataset.saleBadge,
          }, ...this.getDiscount(dataset),
             ...this.getCategories(dataset),
             ...this.getPromo(dataset),
          }]
        },
      });
    } catch (e) {
      console.log('Something is wrong - ' + e);
    }
  }

  viewItemTag(dataset, quantity) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });

    try {
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          currency: dataset.currency,
          items: [{...{
            item_name: dataset.variantName,
            item_id: dataset.variantSku,
            price: Number(dataset.variantPrice),
            affiliation: dataset.variantAffiliation,
            item_brand: dataset.variantBrand,
            item_variant: dataset.variantName,
            item_list_name: dataset.variantPageTitle,
            quantity: Number(quantity),
            pp_badge: dataset.ppBadge,
            discount_badge: dataset.saleBadge,
          }, ...this.getDiscount(dataset),
             ...this.getCategories(dataset),
             ...this.getPromo(dataset),
          }]
        },
      });
    } catch (e) {
      console.log('Something is wrong - ' + e);
    }
  }

  addTag(event, dataset, quantity) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });

    try {
      window.dataLayer.push({
        event: event,
        ecommerce: {
          currency: dataset.currency,
          value: Number(dataset.orderTotal),
          items: [{...{
            item_name: dataset.variantName,
            item_id: dataset.variantSku,
            price: Number(dataset.variantPrice),
            affiliation: dataset.variantAffiliation,
            item_brand: dataset.variantBrand,
            item_variant: dataset.variantName,
            item_list_name: dataset.variantPageTitle,
            quantity: Number(quantity),
            pp_badge: dataset.ppBadge,
            discount_badge: dataset.saleBadge,
          }, ...this.getDiscount(dataset),
             ...this.getCategories(dataset),
             ...this.getPromo(dataset),
          }]
        },
      });
    } catch (e) {
      console.log('Something is wrong - ' + e);
    }
  }

  getCategories = (dataset) => {
    let categories = dataset.variantCategories ? dataset.variantCategories.split(',') : [];
    let itemCategories = {};

    categories.forEach((c, i) => {
      if (i+1 > 5) return;

      const category = i === 0 ? 'item_category' : `item_category${i+1}`;

      itemCategories[category] = c;
    })

    return itemCategories;
  }

  getDiscount = (dataset) => {
    if (!dataset.salePercentage) return {};

    return { percentage_discount: dataset.salePercentage, discount: Number(dataset.saleDiscount) }
  }

  getPromo = (dataset) => {
    if (!dataset.variantPromotionName) return {};

    return { promotion_name: dataset.variantPromotionName };
  }

  ga404 = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });

    window.dataLayer.push({
      event: 'error_404',
    });
  }

  gaNoResults = () => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'no_results',
    });
  }
}

export const googleTagManager = new GoogleTagManager();
