import { Controller } from 'stimulus';
import { variantQuantity } from '../packs/scripts/helpers/VariantQuantity';
import { accounting } from 'accounting';

export default class extends Controller {
  static targets = ['showColor', 'showProperties', 'selectedVariant', 'variantPrice',
                    'variantSale', 'variantPreSale', 'quantity']

  initialize() {
    const variantDataset = this.selectedVariantTarget.dataset;

    this.prices = JSON.parse(variantDataset.prices);
    this.variantOptionsList = JSON.parse(variantDataset.variantoptionslist);
    this.propertiesList = {};
    this.selectedVariant = '';
    this.productId = this.selectedVariantTarget.dataset.productId;
    this.salesSidebar = document.getElementById('sales-display');
    this.noSalesSidebar = document.getElementById('no-sales-display');
    this.salesBanner = document.getElementById('variant-sale-banner');
    this.displaySalePercentage = document.getElementById('variant-display-sale-percentage');
    this.saleEndDateDisplay = document.getElementById('current-sale-end-date-display');
    this.saleEndDateDisplayWrapper = document.getElementById('current-sale-end-date-display-wrapper');
    this.lowestHistoricAmountDisplay = document.getElementById('lowest-historic-amount-display');
    this.lowestHistoricAmountDisplayWrapper = document.getElementById('lowest-historic-amount-display-wrapper');

    this.formatOptions = {
      symbol: variantDataset.currency,
      decimal: variantDataset.decimalMark,
      thousand: variantDataset.thousandsSeparator,
      precision: variantDataset.precision,
      format: '%v',
    };

    if (this.hasShowColorTarget) {
      this.colorPresentation = this.showColorTarget.dataset.presentation;
      this.color();
    }
    if (this.hasShowPropertiesTarget) {
      this.option();
    }
    this.setVariantAndUpdateView();
  }

  getColor() {
    this.color();
    this.setVariantAndUpdateView();
  }

  getOption() {
    this.option();
    this.setVariantAndUpdateView();
  }

  setVariantAndUpdateView() {
    const selectedVariant = this.matchingVariant(this.propertiesList);
    this.selectedVariant = selectedVariant;

    if (selectedVariant === '') {
      variantQuantity.showOnRequestButton(this.productId);
      return;
    }

    this.selectedVariantTarget.dataset.variantId = selectedVariant.variant_id;
    this.setPrice();
    this.displayCorrectButton();
    this.dispatchEvent(selectedVariant.variant_id);
  }

  dispatchEvent(variantId) {
    const event = new CustomEvent("sendVariantId", { detail: { variant_id: variantId } });
    window.dispatchEvent(event);
  }

  setPrice() {
    if (this.selectedVariant === '') return;

    const price = this.prices[this.selectedVariant.variant_id];

    if (!price) return this.setNoSale();

    const amount = price[0];
    const preSaleAmount = price[1];
    const displaySalePercentage = price[2];
    const lowestHistoricAmount = price[3];
    const saleEndDateDisplay = price[4];
    const displayAmount = accounting.formatMoney(accounting.toFixed(amount, 10),
                                                this.formatOptions);

    this.variantPriceTarget.innerHTML = displayAmount;

    if (lowestHistoricAmount) {
      this.lowestHistoricAmountDisplayWrapper.style.display = 'block';
      this.lowestHistoricAmountDisplay.innerHTML = lowestHistoricAmount;
    } else {
      this.lowestHistoricAmountDisplayWrapper.style.display = 'none';
      this.lowestHistoricAmountDisplay.innerHTML = null;
    }

    if (preSaleAmount) {
      this.setSale(preSaleAmount, displaySalePercentage, saleEndDateDisplay)
    } else {
      this.setNoSale();
    }
  }

  setSale = (preSaleAmount, displaySalePercentage, saleEndDateDisplay) => {
    const displayPreSaleAmount = accounting.formatMoney(accounting.toFixed(preSaleAmount, 10),
                                                        this.formatOptions);

    this.variantPreSaleTarget.innerHTML = displayPreSaleAmount;
    this.displaySalePercentage.innerHTML = displaySalePercentage + '%';
    this.saleEndDateDisplay.innerHTML = saleEndDateDisplay;

    this.noSalesSidebar.style.display = 'none';
    this.salesSidebar.style.display = 'block'
    this.salesBanner.style.display = 'block';
  }

  setNoSale = () => {
    if (this.hasVariantPreSaleTarget) this.variantPreSaleTarget.innerHTML = null;

    this.salesBanner.style.display = 'none';
    this.salesSidebar.style.display = 'none';
    this.noSalesSidebar.style.display = 'block';
  }

  displayCorrectButton() {
    const quantity = this.quantity();
    variantQuantity.refreshButtonQuantity(this.quantityTarget, quantity);

    if (this.selectedVariant.total > quantity) {
      variantQuantity.showAddToCartButton(this.productId);
    } else {
      variantQuantity.showOnRequestButton(this.productId);
    }
  }

  quantity() {
    if (this.selectedVariant === '') return 0;

    const variantQuantities = JSON.parse(this.quantityTarget.dataset.itemCounts);
    const variantId = String(this.selectedVariant.variant_id);
    return variantQuantities[variantId] || 0;
  }

  matchingVariant(propertiesList) {
    let selectedVariant = '';
    for (let optionEl of this.variantOptionsList) {
      let mismatch = 0;
      for (let key in propertiesList) {
        if (propertiesList[key] != optionEl[key]) {
          mismatch += 1;
        }
      }
      if (mismatch === 0) {
        selectedVariant = optionEl;
        break;
      }
    }
    return selectedVariant;
  }

  color() {
    this.propertiesList[this.colorPresentation] =
      this.showColorTargets.find(button => button.checked).getAttribute('data-colorName');
  }

  option() {
    this.showPropertiesTargets.map(target => this.propertiesList[target.dataset.title] = target.value);
  }
}
