import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

class ProductGalleryVariantController {
  constructor() {
    this.DOM = {
      sliderClass: '.js-variant .js-productGallery',
      sliderClassInit: '.js-productGallery__init',
    }
  }

  init() {
    if (document.querySelector(this.DOM.sliderClass) !== null) {
      this.slider();
    } else {
      console.log(`${this.DOM.sliderClass} does not exist in the DOM!`);
    }
  }

  slider() {

    const sliders = document.querySelectorAll(this.DOM.sliderClass);
    if (sliders == null) return

    sliders.forEach(slider => {
      let sliderInit = slider.querySelector(this.DOM.sliderClassInit)

      let sliderInst = new Swiper(sliderInit, {
        loop: false,
        watchOverflow: true,
        watchSlidesVisibility: true,
        centerInsufficientSlides: false,
        spaceBetween: 10,
        slidesPerView: 'auto',
      });

      let variantContainer = document.querySelector('.js-variant')
      if (variantContainer == null) return

      variantContainer.addEventListener('change', function (e) {
        let target = e.target.dataset.choice;
        let slide = variantContainer.querySelector(`[data-variant='${target}']`)
        let index = Array.from(slide.parentNode.children).indexOf(slide)
        sliderInst.slideTo(index)
      });

    });

  }
}

export let productGalleryVariantController = new ProductGalleryVariantController();
