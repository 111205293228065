// js-hide-on-scroll

class ScrollActionsController {
  hide(selector) {
    let target = (selector != null) ? selector : '.js-hide-on-scroll';
    let _target = document.querySelector(target);
    console.log(_target)

    if (_target != null) {
      window.addEventListener('scroll', () => {
        let lastKnownScrollPosition = window.scrollY;
        if (lastKnownScrollPosition > 10) {
          _target.classList.add('is-hidden')
        } else {
          _target.classList.remove('is-hidden')
        }

      });
    }
  }
}


export let scrollActionsController = new ScrollActionsController();
