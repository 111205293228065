import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "aboutAuthor",
    "aboutAuthorButton",
    "tagDescription",
    "tagDescriptionButton",
  ];

  initialize() {
    this.DOM = {
      filterSortForm: ".js-filter-form",
      perPageInput: ".js-per-page-input",
    };

    if (this.hasTagDescriptionTarget) {
      if (
        this.tagDescriptionTarget.clientHeight ===
        this.tagDescriptionTarget.scrollHeight
      ) {
        if (this.hasTagDescriptionButtonTarget)
          this.tagDescriptionButtonTarget.style.display = "none";
      }
    }

    if (this.hasAboutAuthorTarget) {
      if (
        this.aboutAuthorTarget.clientHeight ===
        this.aboutAuthorTarget.scrollHeight
      ) {
        if (this.hasAboutAuthorButtonTarget)
          this.aboutAuthorButtonTarget.style.display = "none";
      }
    }
  }

  filter() {
    const form = this.element.closest(this.DOM.filterSortForm);
    let url = form.action;
    url += this.setPerPageValue(form);
    url += this.setOptionsAndPropertiesValues(form);

    window.location = url;
  }

  reset() {
    const form = this.element.closest(this.DOM.filterSortForm);
    let url = form.action;

    url += this.setPerPageValue(form);

    window.location = url;
  }

  setPerPageValue(form) {
    const perPageValue = form
      .querySelector(this.DOM.perPageInput)
      .value.toString();

    return "?per_page=" + perPageValue;
  }

  setOptionsAndPropertiesValues(form) {
    let queryParams = "";
    form
      .querySelectorAll('input[type="checkbox"]:checked')
      .forEach((checkbox) => {
        const checkboxName = checkbox.name;
        const checkboxValue = checkbox.value.toString();

        queryParams += "&" + checkboxName + "=" + checkboxValue;
      });

    return queryParams;
  }

  aboutAuthor = (e) => {
    if (!this.hasAboutAuthorTarget) return;

    this.handleVisibility(this.aboutAuthorTarget, e);
  };

  tagDescription = (e) => {
    if (!this.hasTagDescriptionTarget) return;

    this.handleVisibility(this.tagDescriptionTarget, e);
  };

  handleVisibility = (target, e) => {
    if (target.style.display === "block") {
      target.style.maxHeight = `${target.oldClientHeight}px`;

      setTimeout(() => {
        target.style.display = "-webkit-box";
        target.style.maxHeight = "";
        e.target.innerHTML = "Prikaži više";
      }, "1000");
    } else {
      const height = target.clientHeight;

      target.oldClientHeight = height;
      target.style.maxHeight = `${height}px`;

      target.style.display = "block";

      setTimeout(() => {
        target.style.maxHeight = "2000px"; // Workaround for css transitions. Unreachable max size.
        e.target.innerHTML = "Prikaži manje";
      }, "100");
    }
  };
}
