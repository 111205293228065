import { Controller } from 'stimulus';
import { spreeApiController } from '../packs/scripts/SpreeApiController';
import { addressResponseHandler }  from '../packs/scripts/helpers/handlers/AddressResponseHandler';
import { visibility } from "../packs/scripts/helpers/Visibility";

const addressBasePath = "/api/v2/storefront/account/addresses/";

export default class extends Controller {
  static targets = [
    "listAddress", "addressRow", "addressFullInfo", "addressEdit", "addressDelete", "editDisable", "updateAdd",
    "formcontainer", "editFormContainer", "form", "addnewsection", "addnewsectionbutton",
    "firstname", "lastname", "phone", "city", "zipcode", "streetName", "houseNumber"]

  static classes = ["active"]

  initialize() {
    visibility.showTargets(this.addressEditTargets, 'inline-flex');

    visibility.hideTargets(this.formcontainerTargets);
    visibility.hideTargets(this.editFormContainerTargets);
  }

  edit_address(event) {
    const index = this.htmlElementIndex(this.addressEditTargets, event)
    const addressEditForm = this.editFormContainerTargets[index];
    if (addressEditForm === undefined) return;

    visibility.hideTargets(this.addressEditTargets);
    visibility.hideTargets(this.addnewsectionbuttonTargets);

    visibility.showTargets([addressEditForm], 'block');
  }

  newopen() {
    visibility.showTargets([this.formcontainerTarget], 'block');

    visibility.hideTargets(this.addressEditTargets);
    visibility.hideTargets(this.addnewsectionTargets);
    visibility.hideTargets(this.addnewsectionbuttonTargets);
  }

  editdisable(event) {
    const index = this.htmlElementIndex(this.editDisableTargets, event);
    const addressEditForm = this.editFormContainerTargets[index];
    if (addressEditForm === undefined) return;

    visibility.hideTargets([addressEditForm]);

    visibility.showTargets([this.addnewsectionbuttonTarget], 'block');
    visibility.showTargets(this.addressEditTargets, 'inline-flex');
    visibility.resetForm([this.formTargets[index]]);
  }

  newdisable() {
    visibility.hideTargets(this.formcontainerTargets);

    visibility.showTargets(this.addressEditTargets, 'inline-flex');
    visibility.showTargets(this.addnewsectionbuttonTargets, 'block');
    // if user does not have any address, only hidden address form
    if (this.addressEditTargets.length === 1) {
      visibility.showTargets(this.addnewsectionTargets, 'block');
    }
    visibility.resetForm([this.formTarget]);
  }

  // CRUD requests

  async create_address(event) {
    const index = this.firstnameTargets.length - 1;
    if (index === undefined) return;

    await this.ensureTokens();

    const headers = spreeApiController.prepareHeaders();
    const body = this.prepareCreateAddressBody(index);

    const source = await fetch(addressBasePath, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    await addressResponseHandler.create(this, source, body, event.target);

    return source;
  }

  async update_address(event) {
    const index = this.htmlElementIndex(this.updateAddTargets, event);
    const addressElement = this.addressEditTargets[index];
    if (addressElement === undefined) return;

    await this.ensureTokens();

    const headers = spreeApiController.prepareHeaders();
    const deliveryAddressId = addressElement.dataset.deliveryAddressId;
    const body = this.prepareCreateAddressBody(index);

    const source = await fetch(`${addressBasePath}${deliveryAddressId}`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(body)
    });
    await addressResponseHandler.update(this, source, body, index, event.target);

    return source;
  }

  async destroy_address(event) {
    const index = this.htmlElementIndex(this.addressDeleteTargets, event);
    const addressElement = this.addressDeleteTargets[index];
    if (addressElement === undefined) return;

    await this.ensureTokens();

    const headers = spreeApiController.prepareHeaders();
    const deliveryAddressId = addressElement.dataset.deliveryAddressId;

    const source = await fetch(`${addressBasePath}${deliveryAddressId}`, {
      method: 'DELETE',
      headers: headers
    });
    await addressResponseHandler.destroy(this, source, deliveryAddressId);

    return source;
  }

  // Helper methods

  htmlElementIndex(elements, event) {
    if (event.target instanceof HTMLButtonElement) {
      return elements.findIndex(t => t === event.target);
    }
    else {
      return elements.findIndex(t => t === event.target.parentElement);
    }
  }

  prepareCreateAddressBody(index) {
    return {
      address: {
        firstname: this.firstnameTargets[index].value,
        lastname: this.lastnameTargets[index].value,
        phone: this.phoneTargets[index].value,
        zipcode: this.zipcodeTargets[index].value,
        city: this.cityTargets[index].value,
        street_name: this.streetNameTargets[index].value,
        house_number: this.houseNumberTargets[index].value
      }
    }
  }

  // TODO: move to global.js
  async ensureTokens() {
    if (spreeApiController.oauthToken.length === 0) {
      const source = await fetch(`/api_tokens`, {method: 'GET'});
      const data = await source.json();

      spreeApiController.oauthToken = data.oauth_token;
    }
  }
}
