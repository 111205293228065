import { Controller } from 'stimulus';
import { googleTagManager } from "../packs/scripts/helpers/GoogleTagManager";

export default class extends Controller {
  initialize() {
    const detector = document.getElementById('signin-detector');

    if (!detector) return;

    if (detector.dataset.signup === 'true') {
      googleTagManager.gaSignin('sign_up');
    } else if (detector.dataset.login === 'true') {
      googleTagManager.gaSignin('login');
    }
  }
}
