const loadGoogleMapsApi = require('load-google-maps-api');
import { stylers } from './stylers'
import markerTmpl from './marker.tmpl'

let globalMarkerStore = { markers: {} }

export default class GMap {
  constructor(mapClass, apiKey) {
    this.DOM = {
      mapEl: document.querySelector(mapClass),
    }
    this.SETTINGS = {
      api: apiKey,
    }

    this.zoom = parseFloat(this.DOM.mapEl.dataset.zoom ? this.DOM.mapEl.dataset.zoom : 12)
    this.map
    this.data = []
    this.userIcon
    this.markers
  }

  init() {
    // Call map renderer
    this.load(this.SETTINGS.api)
  }



  load(api) {
    loadGoogleMapsApi({ key: api }).then(() => {

      this.markers = Array.from(this.DOM.mapEl.querySelectorAll('.js-marker'))
      this.prepareMarkers(this.markers)

      this.renderMap(this.DOM.mapEl, this.data)
      this.userGeolocation(this.map, this.userIcon)
    })
  }


  prepareMarkers(markers) {
    this.userIcon = {
      url: this.DOM.mapEl.dataset.userIcon ? this.DOM.mapEl.dataset.userIcon : stylers.icons.user,
      scaledSize: new google.maps.Size(38, 50)
    }
    markers.forEach(marker => {
      this.data.push({
        lat: parseFloat(marker.dataset.lat) ? parseFloat(marker.dataset.lat) : 0,
        lng: parseFloat(marker.dataset.lng) ? parseFloat(marker.dataset.lng) : 0,
        address: marker.dataset.address ? marker.dataset.address : "",
        title: marker.dataset.title ? marker.dataset.title : "Map",
        stockLocationElem: marker.dataset.stocklocationelem ? marker.dataset.stocklocationelem : 0,
        defaultIcon: {
          url: marker.dataset.default ? marker.dataset.default : stylers.icons.pharmacy,
          scaledSize: new google.maps.Size(38, 50)
        },
        activeIcon: {
          url: marker.dataset.active ? marker.dataset.active : stylers.icons.pharmacy_active,
          scaledSize: new google.maps.Size(38, 50)
        }
      })
    });
    return this.data
  }

  renderMap(mapEl, data) {
    const options = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: stylers.styles,
      zoom: this.zoom,
      center: {
        lat: data[0].lat,
        lng: data[0].lng
      },
    }
    this.map = new google.maps.Map(mapEl, options)

    const infowindow = new google.maps.InfoWindow()
    let gmMarkers = []

    globalMarkerStore.map = this.map;
    globalMarkerStore.icons = stylers.icons;
    globalMarkerStore.infowindow = infowindow;
    globalMarkerStore.gmMarkers = gmMarkers;

    for (let i = 0; i < data.length; i++) {
      this.renderMarker(this.map, data[i], infowindow, gmMarkers)
    }

    const targetNode = document.querySelector('#output');
    const config = { attributes: true, childList: true, subtree: true };
    const callback = function(mutationList, observer) {
      document.querySelectorAll('input[name="stockLocation"]').forEach(button => {
        button.addEventListener('click', (e) => {
          const id = button.value;
          const marker = globalMarkerStore.markers["stock_location_" + id];

          if(!marker) {
            return;
          }

          for (let i = 0; i < globalMarkerStore.gmMarkers.length; i++) {
            if (i == 0) {
              if (marker !== globalMarkerStore.gmMarkers[i]) {
                  globalMarkerStore.gmMarkers[i].setMap(null);
              }
            }
          }

          globalMarkerStore.infowindow.setContent(marker.content);
          globalMarkerStore.infowindow.open(globalMarkerStore.map, marker);
          globalMarkerStore.map.panTo(marker.getPosition());
          marker.setIcon(globalMarkerStore.icons.pharmacy_active);
        });
      });
    }

    const observer = new MutationObserver(callback);

    observer.observe(targetNode, config);
  }

  renderMarker(map, data, infowindow, gmMarkers) {
    const tmpl = markerTmpl(data)

    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(data.lat, data.lng),
      map: map,
      icon: data.defaultIcon,
      title: data.title,
      stockLocationElem: data.stockLocationElem,
      content: tmpl,
      animation: google.maps.Animation.DROP
    })

    gmMarkers.push(marker)

    this.handleMarkerClick(map, marker, infowindow, gmMarkers, data)

    globalMarkerStore.markers[data.stockLocationElem] = marker;
  }


  handleMarkerClick(map, marker, infowindow, gmMarkers, data) {
    google.maps.event.addListener(marker, 'click', function () {
      for (let i = 0; i < gmMarkers.length; i++) {
        gmMarkers[i].setIcon(data.defaultIcon);
      }
      infowindow.setContent(marker.content)
      infowindow.open(map, marker)
      map.panTo(marker.getPosition());
      this.setIcon(data.activeIcon);

      if (marker.stockLocationElem != 0) {
        document.querySelector("#" + marker.stockLocationElem).click();
      }
    })

    google.maps.event.addListener(map, 'click', function (event) {
      marker.setIcon(data.defaultIcon);
      if (infowindow) {
        infowindow.close(map, infowindow)
      }
    })
  }

  mapPan(map, marker) {
    map.panTo({
      lat: marker[0].lat,
      lng: marker[0].lng
    });
  }

  userGeolocation(map, userIcon) {
    // Get proper error message based on the code.
    const getPositionErrorMessage = code => {
      switch (code) {
        case 1:
          return 'Permission denied.';
        case 2:
          return 'Position unavailable.';
        case 3:
          return 'Timeout reached.';
      }
    }
    // Get user's location
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          let marker = new google.maps.Marker({
            position: new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
            map: map,
            icon: userIcon,
            animation: google.maps.Animation.DROP
          })
          // Center map to user's position.
          map.panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        // err => console.log(`Error (${err.code}): ${getPositionErrorMessage(err.code)}`)
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  }
}
