import autoComplete from "@tarekraafat/autocomplete.js";
import GMap from "./GMapOrderLocator";
import { stylers } from "./GMapOrderLocator/stylers";

export default class OrderLocationControllerV2 {
  constructor(modal) {
    this.modal = modal;
    this.DOM = {
      filter: this.modal.querySelector(".js-orderLocationV2-filter"),
      filterDropdown: this.modal.querySelector(
        ".js-orderLocationV2-filterDropdown"
      ),
    };
    this.clickCallback = (e) => {
      let text = e.target.innerHTML;
      if (text.endsWith("-")) {
        this.DOM.filterDropdown.style.display = "none";
        e.target.innerHTML =
          e.target.innerHTML.substring(0, e.target.innerHTML.length - 1) + " +";
      } else {
        this.DOM.filterDropdown.style.display = "block";
        e.target.innerHTML =
          e.target.innerHTML.substring(0, e.target.innerHTML.length - 1) + " -";
      }
    };
  }

  init(target) {
    const autoCompleteJS = new autoComplete({
      selector: target,
      data: {
        src: async (query) => {
          try {
            if (query.length < 3) {
              return [];
            }

            const element = document.getElementById(target.slice(1));
            const parsedResponse = await this.getSuggestions(element, query);

            return parsedResponse;
          } catch (error) {
            return error;
          }
        },
        cache: false,
        keys: ["label"],
      },
      placeHolder: "Upiši svoju lokaciju za odabir ljekarne",
      resultItem: {
        tag: "li",
        class: "autoComplete_result",
        highlight: "autoComplete_highlight",
        selected: "autoComplete_selected",
      },
      events: {
        input: {
          selection: (event) => {
            const feedback = event.detail;
            const selection = feedback.selection.value[feedback.selection.key];

            autoCompleteJS.input.value = selection;
            autoCompleteJS.input.setAttribute(
              "data-place_id",
              feedback.selection.value["place_id"]
            );
            this.redirectGoogleMap(feedback.selection.value["place_id"]);
          },
        },
      },
    });

    if (this.DOM.filter) {
      this.DOM.filter.removeEventListener("click", this.clickCallback);
      this.DOM.filter.addEventListener("click", this.clickCallback);
    }
  }

  async redirectGoogleMap(placeId) {
    const url = document
      .getElementById("autoComplete")
      .getAttribute("data-find_nearest-url");
    let result = await fetch(`${url}?place_id=${placeId}&limit=0`);
    const data = await result.json();
    let markerElements = [];
    let address = data["address_details"]["data"]["attributes"];
    let elem = this.renderMarkerElement(
      address.latitude,
      address.longitude,
      address.street_name,
      `${address.street_name}, ${address.zipcode}, ${address.city}`
    );
    markerElements.push(elem);

    data["stock_locations"]["data"].forEach(function (location, index) {
      address = location["attributes"]["address"]["attributes"];

      let elem = document.createElement("span");
      elem.classList.add("js-marker");
      elem.setAttribute("data-lat", address.latitude);
      elem.setAttribute("data-lng", address.longitude);
      elem.setAttribute("data-title", location["attributes"].name);
      elem.setAttribute(
        "data-address",
        `${address.street_name}, ${address.zipcode}, ${address.city}`
      );
      elem.setAttribute("data-default", stylers.icons.pharmacy);
      elem.setAttribute("data-active", stylers.icons.pharmacy_active);
      elem.setAttribute(
        "data-stockLocationElem",
        "stock_location_" + location["id"]
      );
      markerElements.push(elem);
    });

    var mapElem = document.querySelector(".js-locator-order-map");
    markerElements.forEach(function (elem) {
      mapElem.append(elem);
    });

    let gmapOrder = new GMap(".js-locator-order-map", window.GOOGLE_MAPS_API);
    let markerData = gmapOrder.prepareMarkers(markerElements);

    gmapOrder.renderMap(mapElem, markerData);
  }

  renderMarkerElement(lat, lng, title, address) {
    var elem = document.createElement("span");
    elem.classList.add("js-marker");
    elem.setAttribute("data-lat", lat);
    elem.setAttribute("data-lng", lng);
    elem.setAttribute("data-title", title);
    elem.setAttribute("data-address", address);
    elem.setAttribute("data-default", stylers.icons.user);
    elem.setAttribute("data-active", stylers.icons.user_active);
    return elem;
  }

  async getSuggestions(element, query) {
    const selectedCity = document.getElementById("selected-city-element");
    const customSelectAddress = document.getElementById(
      "custom-select-address-city"
    );

    const isHidden = (el) => {
      const style = window.getComputedStyle(el);
      return style["display"] === "none" || style["visibility"] === "hidden";
    };

    if (isHidden(customSelectAddress)) {
      const cityValue = selectedCity.value;
      if (cityValue !== "Unesite grad") {
        query = query + " " + cityValue;
      }
    }

    const url = element.getAttribute("data-suggest_street-url");
    const source = await fetch(`${url}?street_name=${query}`);
    const data = await source.json();
    return this.parseData(data);
  }

  parseData(data) {
    return data["data"];
  }
}
