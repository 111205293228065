import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

class HeroSliderController {
  constructor() {
    this.DOM = {
      sliderClass: '.js-heroSlider',
      sliderClassInit: '.js-heroSlider__init',
    }
  }

  init() {
    if (document.querySelector(this.DOM.sliderClass) !== null) {
      this.slider();
    } else {
      console.log(`${this.DOM.sliderClass} does not exist in the DOM!`);
    }
  }

  slider() {

    const sliders = document.querySelectorAll(this.DOM.sliderClass);
    if (sliders == null) return

    sliders.forEach(slider => {
      let sliderInit = slider.querySelector(this.DOM.sliderClassInit)
      let sliderInst = new Swiper(sliderInit, {
        loop: false,
        watchOverflow: true,
        watchSlidesVisibility: true,
        centerInsufficientSlides: true,
        spaceBetween: 0,
        slidesPerView: 1,
        slidesPerGroup: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
          // dynamicMainBullets: 2,
        },
        autoplay: {
          delay: 4000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
          reverseDirection: false,
        },
      });
    });


  }

}

// export default heroSliderController;
export let heroSliderController = new HeroSliderController();
