class MoreLessController {
  constructor() {
    this.section
    this.trigger
    this.list
    this.li
    this.toggleClass
  }
  init() {
    this.section = document.querySelector('.js-moreless')
    this.trigger = '.js-moreless__trigger'
    this.list = '.js-moreless__list'
    this.li = '.js-moreless__li'
    this.toggleClass = 'state--all'


    let triggers = document.querySelectorAll(this.trigger)

    let sections = document.querySelectorAll('.js-moreless')
    sections.forEach(section => {
      let trigger = section.querySelector(this.trigger)
      let list = section.querySelector(this.list)
      let showItemNumber = trigger.dataset.itemNumber
      let item = section.querySelector(this.li)
      let itemHeight = item.offsetHeight
      list.style.height = `${showItemNumber * itemHeight}px`
    })

    triggers.forEach(trigger => {
      trigger.addEventListener('click', (e) => {
        this.toggle(e)
      });
    });

  }

  toggle(e) {
    let trigger = e.currentTarget
    let section = trigger.closest('.js-moreless')
    let list = section.querySelector(this.list)
    let maxItemNumber = trigger.dataset.itemNumber
    let showItemNumber = trigger.dataset.itemShow
    let item = section.querySelector(this.li)
    let itemHeight = item.offsetHeight
    section.classList.toggle(this.toggleClass)

    if (section.classList.contains(this.toggleClass)) {
      list.style.height = `${showItemNumber * itemHeight}px`
      trigger.textContent = trigger.dataset.hideText
    } else {
      list.style.height = `${maxItemNumber * itemHeight}px`
      trigger.textContent = trigger.dataset.showText
    }

  }

}



export let moreLessController = new MoreLessController();
