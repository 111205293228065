import { Controller } from 'stimulus';
import { googleTagManager } from "../packs/scripts/helpers/GoogleTagManager";

export default class extends Controller {
  static targets = ['promotionInfo'];

  initialize() {
    if (!this.hasPromotionInfoTarget) return;

    const promo = this.promotionInfoTarget;
    const options = { root: null, threshold: 0.9 };
    const callback = function(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          googleTagManager.gaPromoBanner('view_promotion', promo.dataset);
          observer.unobserve(promo);
        }
      });
    }
    let observer = new IntersectionObserver(callback, options);

    observer.observe(promo);
  }
}
