import 'array-from-polyfill';

class HeaderController {
  constructor() {
    this.siteBody
    this.siteHtml
    this.nav
    this.navToggleButton
    this.navToggleIcon
    this.targetScroll
  }
  init() {
    this.siteBody = document.querySelector('body')
    this.siteHtml = document.querySelector('html')
    this.nav = document.querySelector('.js-nav')
    this.navToggleButton = document.querySelector('.js-navToggle')
    this.navToggleIcon = document.querySelector('.js-navToggle__icon')
    this.targetScroll = document.querySelector('.js-nav-scroll')

    if (this.nav != null) {
      this.events();
    }
  }

  events() {
    if (this.navToggleButton != null) {
      this.navToggleButton.addEventListener('click', () => {
        this.toggle()
      });
    }

    let navOverlays = document.querySelectorAll('.js-overlay')
    if (navOverlays != null) {
      Array.from(navOverlays).forEach(navOverlay => {
        navOverlay.addEventListener('click', () => {
          this.close()
        });
      })
    }


    // fix bug in chrome: when using autocomplete navigation closes
    // let mql = window.matchMedia('(min-width: 1200px)');
    // let inputItems = this.nav.querySelectorAll('.js-qmm-newsletterField')
    // if (inputItems === null) return

    // if (mql.matches) {
    //   Array.from(inputItems).forEach(item => {
    //     item.addEventListener('focus', (event) => {
    //       let navParent = item.closest('.js-hasChildren')
    //       navParent.classList.add('state--navActive')
    //     }, true);
    //     item.addEventListener('blur', (event) => {
    //       let navParent = item.closest('.js-hasChildren')
    //       navParent.classList.remove('state--navActive')
    //     }, true);
    //   })
    // }
  }

  toggle() {
    this.siteBody.classList.contains('state--navActive') ? this.close() : this.open()
  }

  open() {
    this.navToggleIcon.classList.remove('is-animating-to-open')
    void this.navToggleIcon.offsetWidth
    this.navToggleIcon.classList.add('is-animating-to-closed')
    this.navToggleButton.classList.add('state--active')
    this.siteBody.classList.add('state--navActive')
    this.siteHtml.classList.add('disableScroll')

  }

  close() {
    this.navToggleIcon.classList.remove('is-animating-to-closed')
    this.navToggleButton.classList.remove('state--active')
    void this.navToggleIcon.offsetWidth
    this.navToggleIcon.classList.add('is-animating-to-open')
    this.siteBody.classList.remove('state--navActive')
    this.siteHtml.classList.remove('disableScroll')
  }
}


export let headerController = new HeaderController();
