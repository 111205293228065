import Pristine from 'pristinejs'

class ValidationController {
  constructor() {
    this.defaultConfig = {
      // class of the parent element where the error/success class is added
      classTo: 'js-pristineParent',
      errorClass: 'e-formField--error',
      successClass: 'e-formField--success',
      // class of the parent element where error text element is appended
      errorTextParent: 'js-pristineParent',
      // type of element to create for the error text
      errorTextTag: 'div',
      // class of the error text element
      errorTextClass: 'e-formField__messsage',
    };
    Pristine.addValidator(
      'equals',
      function (val, otherFieldSelector) {
        var other = document.querySelector(otherFieldSelector);
        return other && ((!val && !other.value) || other.value === val);
      },
      'Lozinke nisu jednake',
      1,
      true
    );
  }
  init() {
    let forms = document.querySelectorAll('.js-form')
    if (forms !== null) {
      this.proccess(forms);
    }
  }

  proccess(forms) {
    forms.forEach(container => {
      let form = container.querySelector('form')
      let errorContainer = form.querySelector('.js-error-messages')
      if (errorContainer != null) {
        errorContainer.style.display = 'none'
      }

      let submitBtn = container.querySelector('.js-form__submit')
      if (submitBtn) submitBtn.disabled = true

      let pristineInst = new Pristine(form, this.defaultConfig, true);

      form.addEventListener('reset', (e) => {
        pristineInst.reset()
        if (errorContainer) errorContainer.style.display = 'none'
      })

      form.addEventListener('change', (e) => {
        let valid = pristineInst.validate()
        let errorsArray = pristineInst.getErrors()

        if (valid == true) {
          if (errorContainer) errorContainer.style.display = 'none'
          if (submitBtn) submitBtn.disabled = false
        } else {
          if (errorContainer) errorContainer.style.display = 'block'
          if (submitBtn) submitBtn.disabled = true
        }

        let errorsList = []
        errorsArray.forEach(el => {
          errorsList.push(el.errors[0])
          if (errorsList.length > 0) {
            errorContainer.style.display = 'block'
            errorContainer.innerHTML = `<div class="e-alert e-alert--dangerOutline mt-xs">
              <span class="e-alert__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><path d="M21.9 8.3L19.1 5.6 13.6 11.1 8.2 5.7 5.7 8.2 11.1 13.6 5.6 19.1 8.3 21.9 13.9 16.4 19.3 21.8 21.8 19.3 16.4 13.9z"/></svg>
              </span>
              <span class="e-alert__content">
                <div class="js-error-messages__output">
                  ${errorsList.join(', ')}
                </div>
              </span>
            </div>`
          }
        })
      })


    })
  }
}

export let validationController = new ValidationController();
