import { Controller } from 'stimulus';

export default class extends Controller {

  initialize() {
    this.DOM = {
      forms: '.js-per-page-form'
    }
  }

  per_page() {
    const element = this.element;
    const form = element.closest(this.DOM.forms);
    const value = element.value.toString();
    let url = form.action
    if (url.match(/\?/)) {
      url += '&per_page=' + value;
    } else {
      url += '?per_page=' + value;
    }

    window.location = url;
  }
}
