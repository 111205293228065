import { Controller } from 'stimulus';
import wNumb from 'wnumb';
import Cookies from 'js-cookie';

export default class extends Controller {

  initialize() {
    this.DOM = {
      sortForm: '.js-sort-by-form',
      filterSortForm: '.js-filter-sort-form',
      perPageInput: '.js-per-page-input',
      keywordsInput: '.js-keywords-input',
      sortSelect: '.js-sort-by-select',
      rangeSlider: '#slider-range',
      initialMinPrice: '#slider-range-value1',
      initialMaxPrice: '#slider-range-value2',
      currentMinPrice: 'input[name="min-value"]',
      currentMaxPrice: 'input[name="max-value"]',
    }

    this.timeout = null;
  }

  sort() {
    const element = this.element;
    const form = element.closest(this.DOM.sortForm);
    const value = element.value.toString();
    let url = form.action;

    if (url.match(/\?/)) {
      url += '&sort_by=' + value;
    } else {
      url += '?sort_by=' + value;
    }

    window.location = url;
  }

  filter(event) {
    if (event.target.name !== 'max-value' && event.target.name !== 'min-value') {
      return this.filterActions();
    }

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.filterActions();
    }, 600)
  }

  filterActions() {
    const form = this.element.closest(this.DOM.filterSortForm);
    let url = form.action;

    url += this.setPerPageValue(form);
    url += this.setKeywordsValue(form);
    url += this.setSortByValue(form);
    url += this.setOptionsAndPropertiesValues(form);
    url += this.setPriceValues(form);

    // url += this.setListandPromotion(form);

    window.location = url;
  }

  reset() {
    const form = this.element.closest(this.DOM.filterSortForm);
    let url = form.action;

    url += this.setPerPageValue(form);
    url += this.setSortByValue(form);

    window.location = url;
  }

  setPerPageValue(form) {
    const perPageValue = form.querySelector(this.DOM.perPageInput).value.toString();

    return '?per_page=' + perPageValue;
  }

  setKeywordsValue(form) {
    const keywordsValue = form.querySelector(this.DOM.keywordsInput).value.toString();

    return keywordsValue === '' ? '' : '&keywords=' + keywordsValue;
  }

  setSortByValue(form) {
    const sortValue = form.querySelector(this.DOM.sortSelect).value.toString();

    return '&sort_by=' + sortValue;
  }

  setListandPromotion(form) {
    let lists = '';
    const info = form.querySelector('#filter-list-and-promotion-tag');

    if (!info) return lists;

    if (info.dataset.itemListName) lists += `&from_list=${info.dataset.itemListName}`;
    if (info.dataset.promotionName) lists += `&from_promotion=${info.dataset.promotionName}`;

    return lists;
  }

  setOptionsAndPropertiesValues(form) {
    let queryParams = '';
    form.querySelectorAll('input[type="checkbox"]:checked').forEach(checkbox => {
      const checkboxName = checkbox.name;
      const checkboxValue = checkbox.value.toString();

      queryParams += '&' + checkboxName + '=' + checkboxValue
    })

    return queryParams;
  }

  setPriceValues(form) {
    const rangeSlider = form.querySelector(this.DOM.rangeSlider);
    const moneyFormat = wNumb({
      decimals: 0,
      thousand: rangeSlider.getAttribute('data-thousand'),
      suffix: ' ' + rangeSlider.getAttribute('data-currency')
    });

    const initialMinPrice = moneyFormat.from(form.querySelector(this.DOM.initialMinPrice).getAttribute('initial-value')).toString();
    const initialMaxPrice = moneyFormat.from(form.querySelector(this.DOM.initialMaxPrice).getAttribute('initial-value')).toString();
    const currentMinPrice = form.querySelector(this.DOM.currentMinPrice).value.toString();
    const currentMaxPrice = form.querySelector(this.DOM.currentMaxPrice).value.toString();

    let queryParams = '';

    if (currentMinPrice !== initialMinPrice || currentMaxPrice !== initialMaxPrice) {
      queryParams += '&price=' + [currentMinPrice, currentMaxPrice].join(',')
    }

    return queryParams;
  }

}
